/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-return */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { ModalProvider } from "styled-react-modal";
import { Link, useHistory } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, TwitterShareButton } from "react-share";
import moment from "moment-timezone";
import { Popover, Tooltip } from "antd";
import * as S from "./styles";
import TournamentModal from "../JoinTournament/TournamentModal";
import LeaveModal from "../LeaveModal";
import {
  BackArrow,
  CheckMarkIcon,
  Clipboard,
  ClipboardWhite,
  ClockPending,
  ColoredCheckMark,
  CupIcon,
  FacebookNew,
  FacebookRound,
  ProfileIcon,
  TeamIcon,
  UserIcon,
  ValorantTag,
  WarningIcon,
  XIcon,
} from "../../../../assets/svg";
import { useTournamentHook, usePriceConverter, useTournamentData } from "../../../../hooks";
import { useUserInformation } from "../../../../hooks/UserInformation";
import { useGetSecHeaders } from "../../../../hooks/SecurityHeaders";
import { useWallet } from "../../../../providers/WalletProvider";
import { useAppState } from "../../../../providers/AppStateProvider";
import ConnectAccount from "../../ConnectAccountModal/ConnectAccountModal";
import { isGamingPlatformAccountSet } from "../../../../helpers/account";
import { userReloadState } from "../../../../state/userReloadState";
import { sessionState } from "../../../../state/globalState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { capitalizeFirstLetter, cutUnderNDigits, truncateWithDots } from "../../../../helpers/utils";
import { PoolStatus } from "../../../../constants/games";
import KoinsModal from "../KoinsModal";
import InvitationCodeModal from "../InvitationalTournamentModal/index";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import JoinFormModal from "../JoinFormModal";
import EphereModal from "../EphereModal";
import MaxPlayersReachedModal from "../MaxPlayersReachedModal";
import AddToCalendarModal from "../../AddToCalendarModal";
import TeamJoinPoolModal from "../TeamJoinPoolModal";
import { ToastContainer, toast } from "react-toastify";
import ConnectWalletModalPool from "../../ConnectWalletModal/ConnectWalletModalPool";
import Flex from "../../common/Flex";
import { useUserAddress } from "eth-hooks";
import { TOAST_OPTIONS } from "../../../../constants";

export default function TournamentHeader({
  tournamentData,
  time,
  showTimer,
  poolStarted,
  fetchTournamentData,
  renderCountDownTimer,
}) {
  const {
    id,
    startDate,
    registrationStartDate,
    leaderboard,
    isFinished,
    tokenImage,
    tokenName,
    poolPrize,
    currency,
    entrance,
    name,
    description,
    tokenSymbol,
    poolMode,
    entranceTokenName,
    entranceTokenImage,
    entranceTokenSymbol,
    game,
    joinMode,
    maxPlayers,
    joinBtnEnabled,
    joinBtnAction,
    joinBtnData,
  } = tournamentData;

  const history = useHistory();

  const goBack = () => {
    history.goBack();
    history.goBack(); //TODO: find a more intuitive solution since this is non practical and not a good practise to do
  };

  const { injectedProvider, web3Modal, loadWeb3Modal, logoutOfWeb3Modal, displaySelectionModal, providerClient } =
    useWallet();

  const address = useUserAddress(injectedProvider);

  const { detectMetamask } = useAppState();
  const session = useRecoilValue(sessionState);
  const [isJoined, setIsJoined] = useState(false);
  const [modalContentLoading, setModalContentLoading] = useState(false);
  const [joinedSuccess, setJoinedSuccess] = useState(false); // This boolean is used to display joined successfully message inside modal
  const headers = useGetSecHeaders();
  const formattedStartDate = moment(startDate).format("MMM DD, h:mm A");
  const [seconds, setSeconds] = useState(time);
  const [timeText, setTimeText] = useState("");
  const [filterActive, setFilterActive] = useState("valorant");
  const { data, isLoading, error, joinTournament, leaveTournament } = useTournamentHook();
  const [type, setType] = useState("join");
  const defModalButtonText = "Join";
  const [modalButtonText, setModalButtonText] = useState(defModalButtonText);
  const [modalButtonDisabled, setModalButtonDisabled] = useState(false);
  const [approveAllchecked, setApproveAllchecked] = useState(false);
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const setUserReload = useSetRecoilState(userReloadState);
  const [connectAccountOpen, setConnectAccountOpen] = useState(false);
  const [statusOnChain, setStatusOnChain] = useState("");
  const { convertedPrice, conversionError, getConvertedValue } = usePriceConverter(tokenSymbol, poolPrize, "USD");
  const [status, setStatus] = useState(PoolStatus.NOT_JOINED);
  const [displayJoinFormModal, setDisplayJoinFormModal] = useState(false);
  const [joinStepsReqObj, setJoinStepsReqObj] = useState({});
  const [showEphereErrorModal, setShowEphereErrorModal] = useState(false);
  const [showMaxPlayersReachedModal, setShowMaxPlayersReachedModal] = useState(false);
  const [customErrMsg, setCustomErrMsg] = useState("");
  const [showTeamJoinPoolModal, setShowTeamJoinPoolModal] = useState(false);
  const [teamJoinModalConfig, setTeamJoinPoolModalConfig] = useState(null);
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const [isCaptain, setIsCaptain] = useState(false);

  const KOINS_GAME_ID = 6;
  const EPHERE_GAME_ID = 7;

  const [koinsList, setKoinsList] = useState([]);
  const [displayKoinsModal, setDisplayKoinsModal] = useState(false);
  const [callbackData, setCallbackData] = useState({});

  const currentTime = Date.now();
  const registrationStartDateFormatted = moment(registrationStartDate);

  const toggleKoinsModal = onCloseCb => {
    if (displayKoinsModal) {
      resetJoinHandlerState();
      onCloseCb && onCloseCb();
    }
    setDisplayKoinsModal(prev => !prev);
  };
  const toggleJoinFormModal = onCloseCb => {
    if (displayJoinFormModal) {
      resetJoinHandlerState();
      onCloseCb && onCloseCb();
    }
    setDisplayJoinFormModal(prev => !prev);
  };

  const toggleTeamJoinModal = onCloseCb => {
    if (showTeamJoinPoolModal) {
      resetJoinHandlerState();
      onCloseCb && onCloseCb();
    }
    setShowTeamJoinPoolModal(prev => !prev);
  };

  const toggleEphereModal = () => {
    if (showEphereErrorModal) {
      resetJoinHandlerState();
      // onCloseCb && onCloseCb();
    }
    setShowEphereErrorModal(prev => !prev);
  };

  const toggleMaxPlayersReachedModal = () => {
    if (showMaxPlayersReachedModal) {
      resetJoinHandlerState();
      // onCloseCb && onCloseCb();
    }
    setShowMaxPlayersReachedModal(prev => !prev);
  };

  const toggleWalletModal = () => {
    setOpenWalletModal(!openWalletModal);
  };

  // to the calendar modal
  const [showCalendarModal, setCalendarModal] = useState(false);
  const toggleCalendarModal = () => {
    if (showCalendarModal) {
      resetJoinHandlerState();
      fetchTournamentData();
    }
    setCalendarModal(prev => !prev);
  };

  const [url, setUrl] = useState("");
  useEffect(() => {
    setUrl(window.location.href.split("#/")[0]);
  }, []);

  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [displayCodeModal, setDisplayCodeModal] = useState(false);
  const toggleCodeModal = () => {
    if (displayCodeModal) {
      resetJoinHandlerState();
    }
    setDisplayCodeModal(prev => !prev);
  };

  const setError = (isError, msg) => {
    setErrorStatus(isError);
    setErrorMsg(isError ? msg : "");
  };

  const toggleAccountSettingModal = () => {
    if (connectAccountOpen) {
      setUserReload(true);
    }
    setConnectAccountOpen(!connectAccountOpen);
  };

  const toggleLeaveModal = () => {
    setOpenLeaveModal(prev => !prev);
  };

  const clickLeaveButton = () => {
    setOpenLeaveModal(true);
  };

  const { checkUserAccount } = useUserInformation();

  const formatCurrency = value =>
    `$${Number(value)
      .toFixed()
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

  useEffect(() => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    if (seconds % 60 > 9) {
      setTimeText(Math.floor(seconds / 60) + ":" + (seconds % 60) + " min");
    } else {
      setTimeText(Math.floor(seconds / 60) + ":0" + (seconds % 60) + " min");
    }

    return () => clearInterval(interval);
  }, [seconds]);

  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const joinBtnRef = useRef();

  const toggleModal = () => {
    if (
      isGamingPlatformAccountSet(session, tournamentData.platform) ||
      tournamentData.game === KOINS_GAME_ID ||
      tournamentData.accountRequired === false // If accountRequired is false, show the join modal
    ) {
      // session.user?.address || tournamentData.entrance === 0
      // TODO: Confirm the condition below
      if (
        tournamentData.entrance === 0 ||
        (session.user?.address &&
          ((web3Modal && web3Modal.cachedProvider) ||
            (providerClient && Array.isArray(providerClient?.accounts) && providerClient?.accounts?.length > 0)))
      ) {
        // In the if below, we need to check the tournamentData entrance as well.
        // Because the if above can be true even if the webapp does not have an account connected. But in that case the if below (without the tournamentData.entrance > 0 condition) would still run and show a notification.
        if (tournamentData.entrance > 0 && session?.user?.address?.toLowerCase() !== address?.toLowerCase()) {
          toast(
            <Flex alignItems="center">
              <WarningIcon style={{ height: "40px", width: "40px" }} /> Please use the wallet connected to your account
            </Flex>,
            TOAST_OPTIONS,
          );
          return;
        }

        setOpacity(0);
        setIsOpen(prevState => {
          if (prevState) {
            setError(false);
            setModalButtonText("Join");
            setModalButtonDisabled(false);
            setJoinedSuccess(false);
          }
          return !isOpen;
        });
      } else {
        // Check if the issue is that thereis no address
        if (!session.user || !session.user.address) {
          setOpenWalletModal(true);
        } else {
          // If only the web3modal is not injected, just show the loadWeb3Modal

          loadWeb3Modal(true); // Check loadWeb3Modal function for explanation regarding  the boolean being passed
        }
      }
    } else {
      setConnectAccountOpen(true);
    }
  };

  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  };

  const beforeClose = () => {
    return new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 0);
    });
  };

  const onClickFilter = item => {
    setFilterActive(item);
  };

  useEffect(() => {
    setIsJoined(leaderboard.findIndex(elem => elem.userId === headers.userId) !== -1);
    setStatusOnChain(leaderboard?.find(elem => elem.userId === headers.userId)?.statusOnChain);

    if (joinMode === "TEAM") {
      // leaderboard.forEach(element => {
      //   const teams = element?.playerData?.players;

      //   //! This state handle the set of states for team tournaments to avoid the other users to see that they can join the pool also
      //   setIsJoined(teams?.find(elem => elem?.userId === headers?.userId));
      //   setStatusOnChain(teams?.find(elem => elem?.userId === headers?.userId)?.statusOnChain);

      //   const captains = teams?.find(elem => elem?.role === "captain");

      //   if (captains?.role?.includes("captain") && captains?.userId === headers?.userId) {
      //     setIsCaptain(true);
      //   }
      // });
      for (let team of leaderboard) {
        const teamPlayers = team?.playerData?.players;
        const playerJoined = teamPlayers?.find(player => player.userId === headers.userId);
        if (playerJoined) {
          setIsJoined(true);
          setIsCaptain(playerJoined.role === "captain");
          break;
        }
      }
    }
  }, [leaderboard, headers.userId]);

  useEffect(() => {
    if (isFinished) {
      setStatus(PoolStatus.FINISHED);
      return;
    }
    if (!isJoined) {
      setStatus(PoolStatus.NOT_JOINED);
    } else if (isJoined && !poolStarted) {
      setStatus(PoolStatus.JOINED_NOT_STARTED);
    } else if (isJoined && poolStarted) {
      setStatus(PoolStatus.JOINED_STARTED);
    }
  }, [isJoined, poolStarted, isFinished]);

  useEffect(() => {
    if (data && data.type === "join") {
      if (isLoading) {
        setModalButtonText("Joining...");
      } else if (!isLoading && data && data?.error === false) {
        setModalButtonText("Joined OK! -> Pending trx result");
        setModalContentLoading(false);
        setJoinedSuccess(true);
        setIsJoined(true);
        setCalendarModal(true);
      } else if (error) {
        setModalButtonDisabled(false);
        setModalButtonText(defModalButtonText);
        setError(true, error);
        setModalContentLoading(false);
      } else {
        setModalButtonDisabled(false);
        setModalButtonText(defModalButtonText);
        setModalContentLoading(false);
      }
    } else if (data && data.type === "leave") {
      if (!error) {
        fetchTournamentData();
      }
    }
    return;
  }, [isLoading, data, error, fetchTournamentData]);
  const resetJoinHandlerState = () => {
    setModalContentLoading(false);
    setModalButtonText("Join");
    setModalButtonDisabled(false);
  };
  // const JoinPool = hasAccount => {
  //   if (!hasAccount) {
  //     setError(true, "User need to connect an account");
  //     setModalButtonDisabled(false);
  //     // ToDo: Connect Account Modal ( check nick )
  //   }
  //   // join pool
  //   tournamentData.approveMax = approveAllchecked;
  //   joinTournament(tournamentData, resetJoinHandlerState);
  // };

  function handleRes(res) {
    setCallbackData(res?.data?.callbackData || {});
    if (res?.data?.configType === "MAX_PLAYERS_REACHED") {
      setCustomErrMsg(res?.message);
      setModalContentLoading(false);
      setShowMaxPlayersReachedModal(true);
    }

    if (res?.data?.joined === false && res?.data?.configType === "JOIN_CONFIGURATION_REQUIRED") {
      setModalContentLoading(false);
      setCustomErrMsg(res?.message);
      setKoinsList(res?.data?.joinConfiguration);
      setDisplayKoinsModal(true);
    }
    if (res?.data?.joined === false && res?.data?.configType === "TEAM_REQUIRED") {
      setModalContentLoading(false);
      setCustomErrMsg(res?.message);
      setTeamJoinPoolModalConfig({ teamsArray: res?.data?.joinConfiguration });
      setShowTeamJoinPoolModal(true);
    }
    if (res?.data?.configType === "INVALID_DIVISION" || res?.data?.configType === "INVALID_ADDRESS") {
      setModalContentLoading(false);
      if (res?.data?.configType === "INVALID_DIVISION" || res?.data?.configType === "INVALID_ADDRESS") {
        setCustomErrMsg(res?.message);
      } else {
        setCustomErrMsg("Something went wrong. Please try again.");
      }
      setShowEphereErrorModal(true);
    }

    if (res?.data?.joined === false && res?.data?.configType === "INVALID_INVITATIONAL_CODE") {
      setCustomErrMsg(res?.message);
      // setCode(res?.data?.invitationalCode);
      setModalContentLoading(false);
      setDisplayCodeModal(true);
      setIsInvalidCode(true);
    }
    if (res?.data?.joined === true && res?.data?.configType === "OK") {
      setIsInvalidCode(false);
      setCalendarModal(true);
    }

    if (res?.data?.joined === false && res?.data?.configType === "JOIN_STEPS_ARE_REQUIRED") {
      setModalContentLoading(false);
      setCustomErrMsg(res?.message);
      setJoinStepsReqObj(res?.data);
      setDisplayJoinFormModal(true);
      setIsInvalidCode(false);
    }
  }
  
  const JoinPool = async () => {
    tournamentData.approveMax = approveAllchecked;
    setCustomErrMsg("");
    setCallbackData({});
    let res = await joinTournament(tournamentData, data => {
      if (data?.error) {
        setError(true, data.data);
        setModalContentLoading(false);
        setModalButtonDisabled(false);
      } else {
        resetJoinHandlerState();
      }
    });

    handleRes(res)
  };

  const koinsJoinPool = async (config, errorHandler) => {
    tournamentData.approveMax = approveAllchecked;
    try {
      await joinTournament(tournamentData, errorHandler, config);
    } catch (err) {
      console.log({ err });
    }
  };

  // Use this function whenever we need to send configuration to a pool
  const joinPoolWithConfig = async (config, errorHandler) => {
    tournamentData.approveMax = approveAllchecked;
    try {
      let res = await joinTournament(tournamentData, errorHandler, {...config, ...callbackData});
      handleRes(res)
    } catch (err) {
      console.log({ err });
    }
  };

  const dynamicFormJoinPool = async (config, errorHandler) => {
    tournamentData.approveMax = approveAllchecked;
    try {
      let res = await joinTournament(tournamentData, errorHandler, {...config, ...callbackData});
      return res;
    } catch (err) {
      console.log({ err });
    }
  };

  const sendJoinCode = async (config, errorHandler) => {
    try {
      const res = await joinTournament(tournamentData, errorHandler, config);
      if (res?.data?.joined === false && res?.data?.configType === "INVALID_INVITATIONAL_CODE") {
        setIsInvalidCode(true);
      } else if (res?.data?.joined === false && res?.data?.configType === "JOIN_CONFIGURATION_REQUIRED") {
        setDisplayCodeModal(false);
        setDisplayKoinsModal(true);
        setKoinsList(res?.data?.joinConfiguration);
      } else if (res?.data?.joined === false && res?.data?.configType === "TEAM_REQUIRED") {
        setDisplayCodeModal(false);
        setTeamJoinPoolModalConfig({ teamsArray: res?.data?.joinConfiguration, ...config });
        setShowTeamJoinPoolModal(true);
      } else if (res?.data?.joined === true && res?.data?.configType === "OK") {
        setIsInvalidCode(false);
      } else {
        setIsInvalidCode(false);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const joinPoolHandler = () => {
    setModalContentLoading(true);
    setError(false);
    setModalButtonText("Join Pool Handler...");
    setModalButtonDisabled(true);
    // checkUserAccount(tournamentData.platform, JoinPool);
    JoinPool();
  };

  const approveAllCheck = () => {
    setApproveAllchecked(!approveAllchecked);
  };

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const clock = setInterval(() => {
      setNow(new Date());
    }, 1000);
    clearInterval(clock);
  }, []);

  const { convertedPrice: convertedEntranceFee, conversionError: conversionEntranceError } = usePriceConverter(
    tokenSymbol,
    entrance,
    "USD",
  );

  const { isMobile } = useWindowSize();

  const getPlayers = leaderboard => {
    // get the players of leaderboard (leaderboard is an array of objects, inside each object there is a playerData object)
    const players = leaderboard.map(player => player.playerData);
    // from playerData object, get the property players (which is an array of objects and get the length of each array)
    const playersLength = players?.map(player => player?.players?.length);
    // sum all the length of players array
    const totalPlayers = playersLength.reduce((a, b) => a + b, 0);
    return totalPlayers || "-";
  };

  const poolStatusForSocial = useMemo(() => {
    const _status = tournamentData.poolStatus;

    return _status === "FINISHED" || _status === "FINISHED_COLLECTING_DATA"
      ? `Finished`
      : _status === "RUNNING" || poolStarted
      ? `Running`
      : registrationStartDate
      ? registrationStartDate < Date.now()
        ? `Registration open`
        : `Registration opens in ${
            registrationStartDate - Date.now() > 86400000
              ? `${Math.floor((registrationStartDate - Date.now()) / 86400000)} days`
              : `${Math.floor((registrationStartDate - Date.now()) / 3600000)} hours`
          } `
      : "";
  }, [registrationStartDate, tournamentData.poolStatus]);

  const socialMediaText = `${tournamentData.name}\nStart Date: ${moment(tournamentData.startDate).format(
    "MMM DD, h:mm A",
  )}\n${poolStatusForSocial}\n`;

  function isJoinBtnActionLeave(action) {
    return ["LEAVE", "JOINED_IN_TEAM"]?.includes(action);
  }
  return (
    <>
      <ToastContainer />
      <ModalProvider backgroundComponent={S.FadingBackground}>
        <S.TournamentHeaderContainer>
          <Flex columnGap="12" alignItems="self-end" extraStyles={{ marginBottom: "19px" }}>
            <Link to="#" onClick={goBack}>
              <BackArrow />
            </Link>
            <S.HeaderTitle>{tournamentData.name}</S.HeaderTitle>
          </Flex>
          <S.HeaderWrapper>
            {["pending", "retry"].includes(statusOnChain) && (
              <S.PendingBanner>
                <ClockPending />
                <p>Pending. We're processing your request, it can take some time to be completed.</p>
              </S.PendingBanner>
            )}
            <S.MainWrapper>
              <S.BgImage src={tournamentData.bgImage} alt="" />
              <S.ContentWrapper>
                <S.PoolStatusWrapper>
                  {status === PoolStatus.NOT_JOINED && !poolStarted && registrationStartDate < Date.now() && (
                    <S.PoolRegistrationOpened>Registration Open</S.PoolRegistrationOpened>
                  )}
                  {status === PoolStatus.NOT_JOINED && !poolStarted && registrationStartDate > Date.now() && (
                    <S.PoolRegistrationOpened>
                      Registration Opens in
                      {registrationStartDate - Date.now() > 86400000 ? (
                        <span> {registrationStartDateFormatted?.diff(currentTime, "days")} days</span>
                      ) : (
                        <span> {registrationStartDateFormatted?.diff(currentTime, "hours")} hours</span>
                      )}
                    </S.PoolRegistrationOpened>
                  )}
                  {(status === PoolStatus.JOINED_NOT_STARTED || status === PoolStatus.JOINED_STARTED) &&
                    statusOnChain !== "retry" && (
                      <S.PoolStatus>
                        &nbsp;
                        <CheckMarkIcon />
                        &nbsp; Joined
                      </S.PoolStatus>
                    )}

                  {statusOnChain === "retry" && <S.PoolStatus>Pending</S.PoolStatus>}
                  {status === PoolStatus.FINISHED && <S.PoolStatus>Finished</S.PoolStatus>}
                  {status === PoolStatus.JOINED_STARTED && (
                    <S.PlayingWrapper>
                      <div>
                        <span />
                      </div>
                      <p>Playing</p>
                    </S.PlayingWrapper>
                  )}
                </S.PoolStatusWrapper>
                {isMobile && <S.TimeDetailsText>{formattedStartDate}</S.TimeDetailsText>}
                <S.PoolName>{isMobile ? truncateWithDots(description, 18) : description}</S.PoolName>
                <div>{renderCountDownTimer?.()}</div>
                <S.BottomRow>
                  <S.BottomLine>
                    <Tooltip
                      title={joinBtnData?.btnMessage}
                      color="#7265C8"
                      placement="top"
                      overlayInnerStyle={{ borderRadius: "6px", zIndex: 99999 }}
                    >
                      {/* Adding a wrapper div for the Tooltip styling */}
                      <div>
                        <S.HeaderBtn
                          disabled={!joinBtnData?.btnEnabled}
                          ref={joinBtnRef}
                          id={joinBtnData?.btnAction === "JOIN" ? "analytics-join-pool" : "header-btn"}
                          isLeaveBtn={isJoinBtnActionLeave(joinBtnData?.btnAction)}
                          onClick={() => {
                            if (joinBtnData?.btnAction === "JOIN") {
                              detectMetamask(toggleModal);
                            } else if (isJoinBtnActionLeave(joinBtnData?.btnAction)) {
                              clickLeaveButton();
                            }
                          }}
                        >
                          <span>{capitalizeFirstLetter(joinBtnData?.btnAction)}</span>
                        </S.HeaderBtn>
                      </div>
                    </Tooltip>
                    <S.TimeAndIconsWrapper>
                      {!isMobile && <S.TimeDetailsText>{formattedStartDate}</S.TimeDetailsText>}
                      <S.TeamWrapper>
                        <S.PlayerIconWrapper>
                          {joinMode === "TEAM" ? (
                            <TeamIcon style={{ height: "13px" }} />
                          ) : (
                            <ProfileIcon style={{ height: "13px" }} />
                          )}
                        </S.PlayerIconWrapper>
                        <S.DetailsText>
                          {leaderboard.length}/{maxPlayers}&nbsp;&nbsp;{joinMode === "TEAM" ? "teams" : "players"}
                        </S.DetailsText>
                      </S.TeamWrapper>
                      {joinMode === "TEAM" && (
                        <S.PlayerIconWrapper>
                          <UserIcon />
                          <S.DetailsText>{getPlayers(leaderboard)}&nbsp;Players</S.DetailsText>
                        </S.PlayerIconWrapper>
                      )}
                      {/* {tokenImage && (
                      <S.IconsWrapper>
                        <S.CriptoIconWrapper>
                          <img src={tokenImage} alt="token logo" height={30} width={30} />
                        </S.CriptoIconWrapper>
                      </S.IconsWrapper>
                    )} */}
                    </S.TimeAndIconsWrapper>
                  </S.BottomLine>
                  <S.BottomLine>
                    {!isMobile && (
                      <S.ShareBtnContainer isFinished={isFinished}>
                        <Popover
                          content={
                            <div style={{ display: "flex", columnGap: "15px", alignItems: "baseline" }}>
                              <div>
                                <TwitterShareButton url={window?.location?.href} title={socialMediaText}>
                                  <XIcon />
                                </TwitterShareButton>
                              </div>
                              <div>
                                <FacebookShareButton url={window?.location?.href} title={socialMediaText}>
                                  <FacebookRound style={{ width: "20px", height: "20px", paddingTop: "4px" }} />
                                </FacebookShareButton>
                              </div>
                              <div>
                                <div
                                  onClick={async () => {
                                    toast.success(`Pool Link Copied Successfully`, {
                                      ...TOAST_OPTIONS,
                                      style: { ...TOAST_OPTIONS?.style, width: "300px !important" },
                                    });
                                    await navigator.clipboard.writeText(window?.location?.href || "");
                                  }}
                                >
                                  <ClipboardWhite
                                    style={{ width: "20px", height: "20px", paddingTop: "4px", cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                          trigger="click"
                          placement="left"
                          showArrow={false}
                          overlayStyle={{ background: "transparent" }}
                          overlayInnerStyle={{
                            height: "33px",
                            background: "#3F2FAA",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "6px",
                            columnGap: "200px",
                          }}
                        >
                          <S.ShareBtn>Share</S.ShareBtn>
                        </Popover>
                      </S.ShareBtnContainer>
                    )}
                  </S.BottomLine>
                </S.BottomRow>
              </S.ContentWrapper>
            </S.MainWrapper>
            <S.Img src={tournamentData.image} alt="" />
          </S.HeaderWrapper>
          {isJoined && (
            <LeaveModal
              isOpen={openLeaveModal}
              tournamentData={tournamentData}
              toggleModal={toggleLeaveModal}
              fetchTournamentData={fetchTournamentData}
            />
          )}
          <TournamentModal
            timeText={timeText}
            isOpen={isOpen}
            opacity={opacity}
            toggleModal={toggleModal}
            buttonText={modalButtonText}
            buttonHandler={joinPoolHandler}
            // The additional checks of joinbtnEnabled and session.user are not necessary since the modal should not be able to open without these, but adding the checks just in case
            buttonDisabled={modalButtonDisabled || !joinBtnEnabled || !session?.user}
            entrance={tournamentData.entrance}
            checkHandler={approveAllCheck}
            checkStatus={approveAllchecked}
            errorStatus={errorStatus}
            errorMessage={errorMsg}
            poolId={id}
            headers={headers}
            startDate={formattedStartDate}
            tokenImage={tokenImage}
            tokenName={tokenName}
            entranceTokenImage={entranceTokenImage}
            entranceTokenName={entranceTokenName}
            entranceTokenSymbol={entranceTokenSymbol}
            name={name}
            isJoined={isJoined}
            image={tournamentData.image}
            bgImage={tournamentData.bgImage}
            leaderboard={leaderboard}
            setIsOpen={setIsOpen}
            modalContentLoading={modalContentLoading}
            isLoading={isLoading}
            joinedSuccess={joinedSuccess}
            poolPrize={poolPrize}
            tokenSymbol={tokenSymbol}
          />
          <ConnectAccount
            modalHeaderText="We need your gaming account"
            isOpen={connectAccountOpen}
            opacity={1}
            toggleModal={toggleAccountSettingModal}
            step={1}
            requiredGameId={game}
            afterConnectCb={() => {
              if (joinBtnRef && joinBtnRef.current) joinBtnRef.current.click();
            }}
          />
        </S.TournamentHeaderContainer>
      </ModalProvider>
      {/* <KoinsModal
        koinsData={koinsList}
        showModal={displayKoinsModal}
        toggleModal={toggleKoinsModal}
        koinsJoinPool={koinsJoinPool}
        entranceFee={entrance}
        approveAllchecked={approveAllchecked}
        convertedEntranceFee={convertedEntranceFee}
        entranceTokenAddress={tournamentData?.entranceTokenAddress}
        contractAddress={tournamentData?.gamePoolContractAddress}
        entranceTokenSymbol={entranceTokenSymbol}
        entranceTokenImage={entranceTokenImage}
      /> */}
      <InvitationCodeModal
        showModal={displayCodeModal}
        toggleModal={toggleCodeModal}
        sendJoinCode={sendJoinCode}
        invalidCode={isInvalidCode}
      />
      <JoinFormModal
        showModal={displayJoinFormModal}
        toggleModal={toggleJoinFormModal}
        joinStepsReqObj={joinStepsReqObj}
        dynamicFormJoinPool={dynamicFormJoinPool}
      />
      <EphereModal showModal={showEphereErrorModal} toggleModal={toggleEphereModal} customErrMsg={customErrMsg} />
      <MaxPlayersReachedModal showModal={showMaxPlayersReachedModal} toggleModal={toggleMaxPlayersReachedModal} />
      <AddToCalendarModal
        showModal={showCalendarModal}
        toggleModal={toggleCalendarModal}
        url={url}
        eventName={tournamentData.name}
        desc={tournamentData.description}
        poolId={tournamentData.id}
        gameId={tournamentData.game}
        startDate={tournamentData.startDate}
        endDate={tournamentData.endDate}
      />
      <TeamJoinPoolModal
        showModal={showTeamJoinPoolModal}
        toggleModal={toggleTeamJoinModal}
        teamJoinModalConfig={teamJoinModalConfig}
        joinPoolWithConfig={joinPoolWithConfig}
        gameDetails={tournamentData.gameDetails}
      />
      <ConnectWalletModalPool
        connectingToPool={true}
        loadWeb3Modal={loadWeb3Modal}
        showModal={openWalletModal}
        setShowModal={setOpenWalletModal}
        toggleModal={toggleWalletModal}
        resumePrevFlow={toggleModal}
        displaySelectionModal={displaySelectionModal}
      />
    </>
  );
}
