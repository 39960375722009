import styled from "styled-components";
import { breakpoints } from "../../themes";

export const MyTeamsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.isLoading === true ? "1fr" : "repeat(auto-fill, minmax(500px, 1fr))")};
  grid-gap: 20px;
  gap: 27px;
  margin-bottom: 20px;
  // center the content of the grid
  ${breakpoints.down(breakpoints.tablet)} {
    padding-right: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 20px;
  ${breakpoints.down(breakpoints.tablet)} {
    margin: 0;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 48px;
  gap: 12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  margin-left: -40px;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  /* background-color: yellow; */
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const FindTeamWrap = styled.div`
  width: calc(100% - 40px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    margin: auto;
  }
`;

export const Title = styled.h1`
  font-family: "Gotham";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 36px;
  color: #ffffff;
`;

export const TeamContainer = styled.div`
  width: 100%;
  height: ${props => (props.height ? `${props.height}` : "314px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 6px;
  background: #3f2faa;
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const CreateTeam = styled.p`
  font-weight: 325;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  ${breakpoints.down(breakpoints.tablet)} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const TeamProfileContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
    ${breakpoints.down(breakpoints.tablet)} {
      font-style: normal;
      font-weight: 325;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }
  }
`;

export const Badge = styled.div`
  width: 21px;
  height: 21px;
  position: absolute;
  background-color: red;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  font-size: 11px;
  font-weight: 325;
  border: 2px solid #3f2faa;
`;

export const FlexButtons = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 37px;
  margin-top: 83px;
  /* margin-top: 86px; */
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
`;

export const Flex = styled.div`
  display: flex;
  ${props => `
    row-gap: ${props.rowGap ? `${props.rowGap}px` : "unset"}};
    column-gap: ${props.columnGap ? `${props.columnGap}px` : "unset"}};
    flex-direction: ${props.direction ? props.direction : "row"};
    align-items: ${props.alignItems ? props.alignItems : "unset"};
    justify-content: ${props.justifyContent ? props.justifyContent : "unset"};
  `}
`;

export const Text = styled.div`
  ${props => `
    color: ${props.color ? `${props.color}` : "#fff"}};
    font-size: ${props.size ? `${props.size}px` : "16px"}};
    font-weight: ${props.weight ? `${props.weight}` : "unset"}};
  `}
`;

export const InviteContainer = styled.div`
  color: #fff;
  background: #312c83;
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 44px;
  justify-content: space-between;
  margin-top: 14px;
  border-radius: 4px;
`;

export const TournamentRow = styled.div`
  height: 88px;
  width: 100%;
  border-radius: 6px;
  background-color: #3f2faa;
  color: #fff;
  display: grid;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
  display: grid;
  grid-template-columns: 2.4fr repeat(6, 1fr);
  /* ${props => `
   opacity: ${props.active ? `1` : "0.4"}};
  `} */

  @media (max-width: 768px) {
    div {
      display: none;
    }

    div:nth-child(1) {
      display: flex;
      gap: 16px;
    }
    div:nth-child(2) {
      display: block;
    }
    div:nth-child(7) {
      display: block;
    }

    display: flex;
    justify-content: space-between;
  }
`;

export const FinishButton = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#3f2faa")};
  outline: none;
  border: none;
`;

export const PoolButtonContainer = styled.div`
  ${props => `
   background-image: ${
     props.prizeWon
       ? "unset"
       : props.currentTime > props.endDate
       ? "linear-gradient(to right, #E95151, #E95151)"
       : props.currentTime > props.startDate && props.endDate > props.currentTime
       ? "linear-gradient(to right, #77ca70, #77ca70)"
       : "linear-gradient(to right, #e544ff, #ff9b18)"
   }};
  `}
  width: 108px;
  height: 38px;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

export const TournamentRowHeadings = styled.div`
  /* border: 2px solid red; */
  width: 100%;
  background-color: #201366;
  /* background-color: pink; */
  height: 50px;
  color: #fff;
  display: grid;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
  display: grid;
  grid-template-columns: 1.4fr 1fr repeat(6, 1fr);

  @media (max-width: 768px) {
    display: none;
  }
`;

export const TabsTitle = styled.h3`
  font-size: 28px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  color: #ffffff;
`;

export const TabsBar = styled.div`
  display: flex;
  margin-bottom: 35px;
`;

export const TabButton = styled.div`
  width: ${props => (props.width ? props.width : "90px")};
  opacity: ${props => (props.isLoading ? 0.5 : 1)};
  height: 40px;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "325")};
  font-size: 14px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-bottom: ${props => (props.border ? props.border : "none")};
  border-radius: 3px;

  :hover {
    cursor: ${props => (props.isLoading ? "default" : "pointer")};
  }
`;

export const TabPanel = styled.div`
  height: ${props => (props.height ? props.height : "")};
`;

export const TokenImg = styled.img`
  width: 16px;
  height: 14px;
  margin-right: 8px;
  margin-left: 8px;
`;

export const PoolLink = styled.span`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 72px 136px 38px;

  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 136px 38px;
  }

  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 136px 16px;
  }
`;

export const SearchContainer = styled.div`
  width: 284px;
  height: 47px;
  border-radius: 30px;
  background-color: #3f2faa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    /* margin-left: 24px; */
    background-color: transparent;
    width: 70%;
    border: 0;
    outline: none;
    color: #fff;
    margin-left: 18px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SearchBtn = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
`;

export const TeamFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  @media (max-width: 600px) {
    flex-direction: column;
    row-gap: 32px;
  }
`;

// S.Flex justifyContent="space-between" style={{ marginBottom: "36px" }}
export const TeamSearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TeamSearchInput = styled.input`
  border: 1px solid #eeeeee80;
  width: 300px;
  border-radius: 4px;
  height: 40px;
  background-color: transparent;
  color: #eeeeee;
  padding-left: 12px;
  padding-right: 20px;
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: ${props => (props.visible ? "block" : "none")};

  & > svg {
    background: none;
  }
`;
