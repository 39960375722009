/* eslint-disable no-else-return */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import styled from "styled-components";
// import { Switch, Popover } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilValue } from "recoil";
// import { serializeTransaction } from "ethers/lib/utils";
import * as S from "./styles";
import Avatar from "../../components/v2/common/Avatar";
import profileImg from "../../assets/profile-page-background.png";
import DecoratedAvatar from "../../components/v2/common/Avatar/DecoratedAvatar";
// import customCursor from "../../assets/svg/not-editable-cursor.svg";
import { ConfettiIcon, DiamondIcon, PiggyBankIcon } from "../../assets/svg";
import confettiAnimation from "../../assets/lotties/confetti_animation.json";
import pigSaveAnimaiton from "../../assets/lotties/pig_save_animation.json";
import confettiSparkles from "../../assets/confetti-sparkles.png";

import {
  // TwitterIcon,
  // InstagramIcon,
  // FacebookIcon,
  // ActivisionLogo,
  // NintendoSwitchLogo,
  // PlayStationLogo,
  // XBoxLogo,
  // SteamLogo,
  SilhouetteBadge,
  SilhouetteTrophy,
  IronBadge,
  GoldBadge,
  BronzeBadge,
  SilverBadge,
  PlatinumBadge,
  DiamondBadge,
} from "../../assets/svg";
import { Button } from "../../components/v2/common/Button";
// import { breakpoints } from "../../themes";
import { sessionState } from "../../state/globalState";
import { useUserInformation } from "../../hooks/UserInformation";
import BlastSpin from "../../components/v2/common/Spin";
import { useEffect } from "react";
import AccountCard from "./AccountCard";
import useCallAxios from "../../hooks/useAxiosCall";

import TournamentsSection from "./TournamentsSection";
import RequestRow from "./RequestRow";
import PrizeSection from "./PrizeSection";
import { useContractLoader, useGetSecHeaders } from "../../hooks";
import { useWallet } from "../../providers/WalletProvider";
import { useRef } from "react";
import { useContext } from "react";
import { RequestContext } from "../../providers/RequestProvider";
import { TOAST_OPTIONS, TOKEN_OPTIONS } from "../../constants";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useUserAddress } from "eth-hooks";
import Modal from "../../components/v2/Modal";
import { CHAIN_TOKEN_NAME, CHAIN_TOKEN_SYMBOL, CLAIM_WAIT_PERIOD } from "../../constants/env";
import Lottie from "react-lottie";
import { TextNoMargin } from "../../components/v2/common/Text";
import MyActivityLoader from "../../components/LoadingSkeleton/MyActivityLoader";

const style = {
  width: "55px",
  height: "25px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  backgroundColor: "#4E3CCA",
  borderRadius: "6px",
  border: "none",
  fontWeight: "350",
  fontSize: "14px",
};

const prizes = [
  {
    icon: <DiamondIcon />,
    text: "Ensure a place in a limited tournament",
    percent: 56,
  },
];

const ModalContent = ({ status, buttonCb, disableBtn, recentClaimPrizeWon = {}, recentClaimTxExplorerURL = "" }) => {
  if (status === "Processing") {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "45px" }}>
        <Lottie options={{ animationData: pigSaveAnimaiton }} height={110} width={110} />
        <div style={{ marginTop: "55px" }}>Claiming prize</div>
        <div style={{ marginTop: "27px", color: "#A498ED", fontSize: "16px", fontWeight: 325 }}>
          We're processing your claim.
        </div>
      </div>
    );
  } else if (status === "Pending") {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px" }}>
        <Lottie options={{ animationData: pigSaveAnimaiton }} height={110} width={110} />
        <div style={{ marginTop: "25px" }}>Claiming prize</div>
        <div
          style={{
            marginTop: "27px",
            color: "#A498ED",
            fontSize: "16px",
            fontWeight: 325,
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          It will take a few minutes to be available. You can close this window while we continue to process it.
        </div>
        <Button
          disabled={disableBtn}
          onClick={buttonCb}
          borderRadius="30px"
          width="83px"
          fontSize="16px"
          fontWeight="350"
          lineHeight="19px"
          height="43px"
          style={{ marginTop: "27px" }}
        >
          Ok
        </Button>
      </div>
    );
  } else if (status === "Claim Successful") {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px" }}>
        <Lottie options={{ animationData: confettiAnimation }} height={110} width={110} />
        <TextNoMargin style={{ marginTop: "55px" }} fontSize="50px" color="#fff">
          {recentClaimPrizeWon && recentClaimPrizeWon["poolPrizeWon"]}{" "}
          {recentClaimPrizeWon && <S.TokenImg src={recentClaimPrizeWon["tokenImage"]} alt="tokenImage" />}
        </TextNoMargin>
        <div style={{ marginTop: "40px", color: "#A498ED", fontSize: "16px", fontWeight: 325 }}>
          You've earned this amount
        </div>

        <a href={recentClaimTxExplorerURL} target="_blank" style={{ color: "#BB7CFC", textDecoration: "none" }}>
          See Transaction
        </a>
        <Button onClick={buttonCb} borderRadius="60px" style={{ marginTop: "27px" }}>
          Close
        </Button>
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default function UserActivity({ setUserEarnings = () => {} }) {
  const session = useRecoilValue(sessionState);
  const [activeTab, setActiveTab] = useState("Tournaments");
  const [playerReqests, setPlayerRequests] = useState([]);
  const [allPools, setAllPools] = useState([]);
  const [isLoadingUserActivity, setIsLoadingUserActivity] = useState(false);
  const [showClaimSubscribeModal, setShowClaimSubscribeModal] = useState(false);
  const [disableCloseModalBtn, setDisableCloseModalBtn] = useState(false);
  // TODO: Change the following to Processing
  const [modalContentStatus, setModalContentStatus] = useState("Processing");
  const [recentClaimPrizeWon, setRecentClaimPrizeWon] = useState(null);
  const [recentClaimTxExplorerURL, setRecentClaimTxExplorerURL] = useState("");
  const lastClaimedPoolId = useRef("");
  const apiCalled = useRef(false); // Ref component to prevent multiple API calls
  const { isMobile } = useWindowSize();
  const [recentPoolsArrEmpty, setRecentPoolsArrEmpty] = useState(false);
  const [allPoolsArrEmpty, setAllPoolsArrEmpty] = useState(false);

  const { injectedProvider } = useWallet();
  const address = useUserAddress(injectedProvider);

  const poolContracts = useContractLoader(injectedProvider);

  const headers = useGetSecHeaders();

  const requestUseContext = useContext(RequestContext);
  const [initialRender, setInitialRender] = useState(false); // this variable will be used to hide the My Activity section until data is loaded to prevent lfickering between loading UI and component

  const { setUserTeamsRequests, userTeamsRequests, totalRequests, setTotalRequests } = requestUseContext;

  const tournamentsSectionRef = useRef(null);
  const handleScroll = () => {
    setActiveTab("Tournaments");
    tournamentsSectionRef.current.scrollIntoView({
      top: 1000,
      behavior: "smooth",
    });
  };

  const acceptJoinInvite = async (teamIdArg, status, setLoader = () => {}) => {
    setLoader(true);
    const response = await callAxiosFunc({
      method: "POST",
      url: `team/join/${teamIdArg}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      body: JSON.stringify({ status }),
    });
    setLoader(false);

    if (response && response.err) {
      toast("Something went wrong. Please try again", TOAST_OPTIONS);
      return { err: response.err };
    } else {
      setUserTeamsRequests(userTeamsRequests?.filter(req => req.teamId !== teamIdArg));
      if (totalRequests > 0) {
        setTotalRequests(totalRequests - 1);
      }
    }
  };

  const { callAxiosFunc } = useCallAxios();

  // Function that subscribes to claim event
  const subscribeToClaimEvent = async (contractId, data, claimEventSuccessCb) => {
    // Gets Blast contract
    const blastContract = Object.values(poolContracts).filter(item => item.address === contractId)[0];

    // TODO: [changeToUserId] Using address here
    if (injectedProvider && address) {
      try {
        // Awaits the PrizeClaimFinished event
        await blastContract.on("PrizeClaimFinished", (_poolId, _address, _position) => {
          // The event will be triggered for all users (frontend clients). We need the following if block to ensure that the claimEventSuccessCb is only called IF the current user had called the function (so if the poolId, address, and position of the event match the user's function call arguments)
          if (
            data.poolOnChainId?.toString() === _poolId.toString() &&
            data.address === _address &&
            data.positionInPool === _position
          ) {
            claimEventSuccessCb && claimEventSuccessCb();
          }
        });
      } catch (err) {
        console.log(err);
        // Unsubscribing the event in case there is an error
        blastContract.off("PrizeClaimFinished");
      }
    }

    return blastContract; //Returning contract so that we can use it to remove the subscription
  };

  async function addToken(tokenSymbol) {
    // Check to return from function in case tokenSymbol not passed
    if (!tokenSymbol) return;

    const { ethereum } = window;

    // Converting tokeSymbol to upper case to correctly compare
    let tokenSymbolUpperCase = tokenSymbol?.toUpperCase();
    // Getting the list of tokens we have in the TOKEN_OPTIONS object
    let tokenList = Object.keys(TOKEN_OPTIONS);
    // If the tokenSymbol we are getting from the backend is not available in the TOKEN_OPTIONS object, we will simply return from this function
    if (!tokenList.includes(tokenSymbolUpperCase)) {
      console.log("token not found in list");
      return;
    }

    // TODO: Remove  true
    // If check to ensure that function only runs in this scenario: IF prizeToken != native_chain_token
    if (CHAIN_TOKEN_SYMBOL?.toUpperCase() !== tokenSymbolUpperCase) {
      try {
        await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: TOKEN_OPTIONS[tokenSymbolUpperCase],
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  // Main claim function.
  // Takes the data and a callback as arguments. The callback will be run if the event is successfully triggered.
  // One slight complex part of this code is that the claimFunc takes a callback, and calls subscribeToClaimEvent which takes a different callback. This is necessary as the cb for subscribeToClaimEvent is used for companenet level tasks such as calling the confirm API and closing the modal, while the claimFunc cb is used for tournament row related tasks and cleanups
  const claimFunc = async (data, claimFuncSuccessCb) => {
    if (!headers.encryptedHeader || !headers.address || !injectedProvider) {
      return;
    }

    const { id, poolContractAddress } = data;
    lastClaimedPoolId.current = id;

    setRecentClaimPrizeWon(null);
    setRecentClaimPrizeWon("");
    setModalContentStatus("Processing");

    const res = await claimApiCall(id);
    const messageHash = res?.data?.claimNonce;
    const signer = injectedProvider.getSigner(headers.address);
    let signedMessage = "";
    try {
      signedMessage = await signer.signMessage(messageHash);
    } catch (err) {
      console.log("signature Error.... ", err);
      throw `Signature Error -> ${err}`;
    }

    await addToken(data?.tokenSymbol);

    setShowClaimSubscribeModal(true);
    setDisableCloseModalBtn(true);

    const timeout = setTimeout(() => {
      setDisableCloseModalBtn(false);
      setModalContentStatus("Pending");
    }, CLAIM_WAIT_PERIOD);

    const blastContract = await subscribeToClaimEvent(
      poolContractAddress,
      { ...data, address: headers?.address },
      () => {
        // Run the following code if conditions meet
        clearTimeout(timeout);
        setDisableCloseModalBtn(false);
        claimApiConfirmCall(id); // No need to await here as we don't need the reponse for any of the future steps
        setUserEarnings(prev => prev - data.poolPrizeWon);
        if (data.id === lastClaimedPoolId.current) {
          // Only run this function for the lastClaimed pool Id (this is stuff related to the modal etc so having old events trigger changes here would be jarring for the user for eg watching the modal change state continuously if multiple events are running simultaneously)
          setModalContentStatus("Claim Successful");
          setRecentClaimPrizeWon({
            poolPrizeWon: data.poolPrizeWon,
            tokenImage: data.tokenImage,
          });
        }
        blastContract.off("PrizeClaimFinished");
        if (claimFuncSuccessCb) {
          claimFuncSuccessCb();
        }
      },
    );

    try {
      const apiResp = await claimApiCall(id, { signature: signedMessage });
      if (apiResp.error) {
        throw new Error();
      }
      if (apiResp?.data?.txExplorerURL) {
        setRecentClaimTxExplorerURL(apiResp?.data?.txExplorerURL);
      }
      return apiResp;
    } catch (e) {
      console.log(e);
      blastContract.off("PrizeClaimFinished");
    }
  };

  const claimApiCall = async (id, body) => {
    if (!headers.encryptedHeader) {
      return;
    }
    try {
      let res = await callAxiosFunc({
        method: "PUT",
        url: `/claimWinnerPrize/${id}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
        body: JSON.stringify(body) || undefined,
      });

      return res;
    } catch (err) {
      console.log({ err });
    }
  };

  const claimApiConfirmCall = async id => {
    if (!headers.encryptedHeader) {
      return;
    }
    try {
      let res = await callAxiosFunc({
        method: "PUT",
        url: `/claimWinnerPrize/confirm/${id}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });

      return res;
    } catch (err) {
      console.log({ err });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("active");
  const firstApiCall = useRef(true);
  const paginationLimit = 3;
  // TODO: Change limit back once pagination works
  async function getProfileActivity(page = 1, onlyActives = false, limit = 4) {
    setIsLoadingUserActivity(true);
    setInitialRender(true); // Explanation of variable given above
    const res = await callAxiosFunc({
      method: "GET",
      url: `/userActivity?page=${page}&actives=${onlyActives}&limit=${paginationLimit}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    setIsLoadingUserActivity(false);
    return res;
  }

  useEffect(() => {
    if (headers.encryptedHeader && headers.token) {
      getProfileActivity(currentPage, currentTab === "active")
        .then(res => {
          if (firstApiCall.current === true && res.data?.activities?.length < 1) {
            setCurrentTab("all");
            setRecentPoolsArrEmpty(true);
            firstApiCall.current = false; // For the first API call, we need to call the recent pools. If the result is empty, we need to call the all pools api call
          } else {
            setAllPools(res?.data); // Will get an object in response with an activities and a totalPages property
            if (currentTab === "all" && res.data?.activities?.length < 1) {
              setAllPoolsArrEmpty(true);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [headers.token, currentPage, currentTab]);

  if(!initialRender) return null; // Explanation of variable given above
  if (isLoadingUserActivity) return <MyActivityLoader />;
  return (
    <>
      <Modal customStyles={{ width: "501.31px", height: "432px" }} showModal={showClaimSubscribeModal} hideCloseBtn>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <img src={confettiSparkles} style={{ position: "absolute", top: 0 }} />

          <div style={{ textAlign: "center", color: "#fff", fontSize: "24px" }}>
            <ModalContent
              status={modalContentStatus}
              buttonCb={() => {
                setShowClaimSubscribeModal(false);
                setDisableCloseModalBtn(true);
                setModalContentStatus("Processing");
              }}
              disableBtn={disableCloseModalBtn}
              recentClaimPrizeWon={recentClaimPrizeWon}
              recentClaimTxExplorerURL={recentClaimTxExplorerURL}
            />
          </div>
          {/* <Button
            disabled={disableCloseModalBtn}
            onClick={() => {
              setShowClaimSubscribeModal(false);
              setDisableCloseModalBtn(true);
              setModalContentStatus("Processing");
            }}
            borderRadius="60px"
          >
            Close
          </Button> */}
        </div>
      </Modal>
      <div ref={tournamentsSectionRef}>
        {activeTab === "Tournaments" ? (
          <TournamentsSection
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            allPools={allPools}
            isLoadingUserActivity={isLoadingUserActivity}
            claimFunc={claimFunc}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            allPoolsArrEmpty={allPoolsArrEmpty}
            recentPoolsArrEmpty={recentPoolsArrEmpty}
          />
        ) : activeTab === "Requests" ? (
          <div>
            {requestUseContext?.userTeamsRequests?.length > 0 ? (
              <div style={{ marginTop: "14px", width: "100%" }}>
                {requestUseContext?.userTeamsRequests?.map(req => (
                  <RequestRow req={req} acceptJoinInvite={acceptJoinInvite} isMobile={false} />
                ))}
              </div>
            ) : (
              <S.RequestText>You don&apos;t have any invites</S.RequestText>
            )}
          </div>
        ) : (
          <PrizeSection prizes={prizes} />
        )}
      </div>
    </>
  );
}
