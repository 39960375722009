import styled from "styled-components";
import { breakpoints } from "../../../../themes";

export const LeaderboardScoreContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const TooltipContainer = styled.div`
  margin-left: 10px;
`;

export const LeaderBoardContainer = styled.div`
  background-color: ${props => (props.isMobile ? "transparent" : props.theme.primaryColor)};
  border-radius: 10px;
  margin-bottom: 32px;
  min-height: ${props => (props.isMobile ? "664px" : "764px")};
  position: relative;
  padding: 0px 0px 51px 0px;
`;

export const Title = styled.p`
  font-size: 24px;
  line-height: 36px;
  color: white;
`;

export const RowHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: grid;
  grid-template-columns: ${props =>
    props.isMobile
      ? "1fr 5fr 1fr"
      : props.colCount
      ? `1fr 1.5fr repeat(${props.colCount}, 1fr) 0.2fr`
      : "1.5fr 2.5fr repeat(4, 1fr) 1.5fr 1fr"};
  ${breakpoints.down(breakpoints.tablet)} {
    grid-template-columns: 1fr 5fr 3fr;
    grid-gap: 5px;
  }
  padding: 0 10px;
`;

export const HeaderText = styled.p`
  font-size: 13px;
  line-height: 36px;
  color: #877add;
  text-align: left;
`;

export const HeaderTextRightAlign = styled.p`
  font-size: 13px;
  line-height: 36px;
  color: #877add;
  text-align: right;
`;

export const ImageWrapper = styled.div`
  text-align: center;
  margin-top: 100px;
`;

export const MainContainer = styled.div`
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 646px;
  max-height: 550px;
  padding-right: 8px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 343px;
    height: 814px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    padding-left: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #3f338ce6;
  }

  ::-webkit-scrollbar-thumb {
    background: #7265c8;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #7265c8e6;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  padding-top: 15px;
  padding-bottom: 30px;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #7265c8;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
  padding-top: 15px;
  padding-bottom: 30px;
  ${breakpoints.down(breakpoints.tablet)} {
    flex-direction: column;
  }
`;

export const IconRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 30px;
`;

export const PreviewContainer = styled.div`
  background: ${props => props.bg || "#201366"};
  border-radius: 6px;
  margin: 25px 0;
  padding: 15px 14px;
  height: 124px;
  /* display: flex; */
  /* gap: 71px; */
  /* justify-content: center; */
`;

export const PreviewBlock = styled.div`
  background: #342788;
  width: 100%;
  height: 59px;
  display: flex;
  padding-left: 23px;
  padding-right: 23px;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  border-radius: 8px;
  margin-top: 8px;
`;

export const ModalTitle = styled.h1`
  font-weight: 325;
  font-size: 28px;
  line-height: 30px;
  color: ${props => props.color || "#ffffff"};
`;

export const TemplateModalTitle = styled.h1`
  font-weight: 325;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.color || "#ffffff"};
  margin: 0;
`;

export const Label = styled.label`
  color: #877add;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  width: 300px;
  height: 40px;
  background: #3f2faa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  color: #ffffff;
  margin-right: 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconContainer = styled.div`
  width: 41px;
  height: 41px;
  background: ${props => props.bg || "#3f2faa"};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReplayButton = styled.button`
  width: 41px;
  height: 41px;
  background: ${props => props.bg || "#3f2faa"};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`;

export const WinnerWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const WinnerContainer = styled.div`
  width: ${props => props.width || "100%"};
  height: 41px;
  border-radius: 6px;
  background: ${props => props.bg};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 13px;
  ${breakpoints.down(breakpoints.tablet)} {
    height: 60px;
  }
`;

export const Nickname = styled.p`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.color || "#ffffff"};
  margin: 0;
  ${breakpoints.down(breakpoints.tablet)} {
    font-size: 12px;
    display: flex;
  }
`;

export const BracketRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BracketContainer = styled.div`
  min-width: 220px;
  height: 37px;
  display: flex;
  align-items: center;
`;

export const Position = styled.div`
  background: #4e3cca;
  border-radius: 6px 0 0 6px;
  height: 37px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayerData = styled.div`
  height: 37px;
  width: 100%;
  background: #342788;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 6px 6px 0;
  padding: 0 10px;
`;

export const BracketColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Text = styled.p`
  color: #ffffff;
  margin: 0;
  font-weight: 325;
  font-size: 12px;
`;

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ScoreRow = styled.div`
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

export const MatchPlayerData = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  ${breakpoints.down(breakpoints.tablet)} {
    flex-direction: column;
    gap: 5px;
  }
`;

export const Score = styled.span``;

export const MatchData = styled.div`
  font-weight: 325;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #877add;
`;

export const MatchRound = styled.span``;

export const Map = styled.span``;

export const TeamContainer = styled.div`
  max-height: 300px;
  background: ${props => props.bg || "#201366"};
  border-radius: 6px;
  margin: 20px 0;
  margin-top: 15px;
  padding: 15px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TeamColumn = styled.div`
  margin: 10px;
  height: 100%;
  max-height: 250px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #7265c8;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

export const TableSection = styled.div`
  margin: 20px 0;
`;

export const DataCell = styled.div`
  height: 43px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 325;
  ${breakpoints.down(breakpoints.tablet)} {
  }
`;

export const TableRow = styled.div`
  display: grid;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  grid-template-columns: ${props => `repeat(${props.colCount}, 1fr)`};
`;

export const TableContainer = styled.div`
  border-radius: 10px;
  background: #3f2faa;
  margin-bottom: 42px;
  max-height: 200px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background: #7265c8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
  /* > div:not(:last-child) {
    border-bottom: 1px solid #3f347e;
  } */
`;

export const PlayerSearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const PlayerSearchInput = styled.input`
  border: 1px solid #eeeeee80;
  width: 300px;
  border-radius: 4px;
  height: 40px;
  background-color: transparent;
  color: #eeeeee;
  padding-left: 12px;
  padding-right: 20px;
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: ${props => (props.visible ? "block" : "none")};

  & > svg {
    background: none;
  }
`;
