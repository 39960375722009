import styled from "styled-components";
import { breakpoints } from "../../themes";

export const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0 0 100px 0;

  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 0px 136px 0px;
  }

  .slick-next,
  .slick-prev {
    top: 75px;
  }
  .slick-list {
    overflow: inherit;
    overflow-x: clip;
  }
`;

export const PageHeader = styled.div`
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
`;

export const PlayerBlocksContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 13px;
`;

export const PlayerBlock = styled.div`
  width: calc(100% / 2 - 13px);
  height: 59px;
  background-color: #342788;
  padding: 0px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  img {
    height: 35px;
    width: 35px;
    border-radius: 500px;
    margin: 0 20px;
  }
  @media (max-width: 852px) {
    width: 100%;
  }
  ${breakpoints.up(breakpoints.laptopL)} {
    width: calc(100% / 3 - 13px);
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => (props.joinMode === "TEAM" ? "#3f2faa" : "#342788")};
  }
`;
export const PlayerDetails = styled.div`
  display: flex;
  align-items: center;
  small {
    color: #877add;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const DottedMenuButton = styled.button`
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
`;

export const MenuContent = styled.div`
  background-color: #3a2f81;
  width: 276px;
  position: absolute;
  right: -16px;
  padding: 8px 17px;
  border-radius: 6px;
  color: #fff;
  z-index: 999;
  overflow: visible !important;
  div {
    margin: 0px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
  }
  hr {
    margin-top: 12px;
    margin-bottom: 9px;
    border: 1px solid #4b3e9a;
    opacity: 0.5;
  }
`;

export const PlayerInfoModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &:hover {
    color: #fff;
  }
  a {
    background-color: #2b2075;
    width: 44px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 4px;
    &:hover {
      color: #fff;
    }
  }
  p {
    font-size: 14px;
    color: #877add;
    line-height: 30px;
    margin: 0;
  }
  input {
    background-color: #2b2075;
    outline: none;
    border-radius: 4px;
    border: 0;
    height: 27px;
    padding-left: 12px;
  }
`;

export const RelativeDiv = styled.div`
  position: relative;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Modal Styles

export const ModalContainer = styled.div`
  height: fit-content;
  min-width: 800px;
  width: 100%;

  ${breakpoints.down(breakpoints.laptopL)} {
    min-width: 600px;
  }

  ${breakpoints.down(breakpoints.tablet)} {
    min-width: 400px;
  }

  ${breakpoints.down(breakpoints.mobileL)} {
    min-width: 300px;
  }

  ${breakpoints.down(breakpoints.mobileM)} {
    min-width: 200px;
  }
`;

export const ModalHeader = styled.h1`
  color: #fff;
  font-family: Gotham;
  font-size: 28px;
  font-style: normal;
  font-weight: 325;
  line-height: 30px;
`;

export const PlayerTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

export const PreviewContainer = styled.div`
  border-radius: 6px;
  margin: 25px 0;
  padding: 15px 14px;
`;

export const PreviewBlock = styled.div`
  border-radius: 10px;
  background: #3f2faa;
  width: 100%;
  height: 59px;
  display: flex;
  padding-left: 23px;
  padding-right: 23px;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  margin-top: 8px;
  text-align: right;
`;

export const Label = styled.label`
  color: #877add;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
`;

export const HeaderContainer = styled.div`
  padding-right: 23px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Nickname = styled.p`
  margin: 0;
`;

export const CopyButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;
