/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import * as S from "./styles";
import PlayerRow from "./PlayerRow/PlayerRow";
import Pagination from "../../Pagination";
import {
  WinnerFirstIcon,
  WinnerSecondIcon,
  WinnerThirdIcon,
  WinnerFirstIconMobile,
  WinnerSecondIconMobile,
  WinnerThirdIconMobile,
  InfoIcon,
  Podium,
  UpdatedPodium,
  PodiumNew,
  FirstPlaceTrophy,
  CupPodiumNew,
  CloseIcon,
} from "../../../../assets/svg";
import img1 from "../../../../assets/leaderboard-img-1.png";
import img2 from "../../../../assets/leaderboard-img-2.png";
import img3 from "../../../../assets/leaderboard-img-3.png";
import { breakpoints } from "../../../../themes";
import { LEADERBOARD_ROWS, LEADERBOARD_PAGE_LIMIT, MOBILE_LEADERBOARD_PAGE_LIMIT } from "../../../../constants/env";
import Avatar from "../../common/Avatar";
import useMedia from "use-media";
import { EPHERE_GAME_ID, KOINS_GAME_ID } from "../../../../constants";
import newAvatarSrc from "../../../../assets/svg/new-default-avatar.svg";
import { truncateWithDots } from "../../../../helpers/utils";
import { TextNoMargin } from "../../common/Text";
import LeaderboardTemplateModal from "./LeaderboardTemplateModal";
import { Button } from "../../common/Button";
import WinnerCard from "../WinnerCard/WinnerCard";
import BG from "../../../../assets/new-bg.png";
import { GroupSelector } from "../GroupSelector/GroupSelector";
import { DynamicLeaderboard } from "./DynamicLeaderboard/DynamicLeaderboard";
import Flex from "../../common/Flex";
import { useDebounce } from "../../../../hooks";
import ContactSection from "../ContactSection";
import BracketsPlayers from "../../../../views/BracketsPlayers/BracketsPlayers";

const images = [img1, img2, img3];
const randomIndex = Math.floor(Math.random() * 2);

const CustomTooltip = ({ className, children, ...restProps }) => (
  <Tooltip
    overlayClassName={className}
    {...restProps}
    getPopupContainer={triggerNode => triggerNode.parentNode}
    placement="right"
  >
    {children}
  </Tooltip>
);

const StyledTooltip = styled(CustomTooltip)`
  @media only screen and (max-width: 528px) {
    max-width: 200px !important;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const FilterButton = styled(Button)`
  background: ${props => (props.active ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : "#3F338C")};
  border-radius: 30px;
  height: 37px;
  flex-shrink: 0;
  padding: 0px 20px;
  font-size: 14px;
  cursor: ${props => (props.shouldDisable ? "default" : "pointer")};
  opacity: ${props => (props.shouldDisable ? 0.5 : 1)};
`;

const TopMenu = styled.div`
  width: 1087px;
  display: flex;
  justify-content: space-between;
`;

const WinnerImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const WinnerPositionContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  width: 800px;
  top: 20px;
  left: 20px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 350px;
  }
  :nth-child(1) {
    left: 0;
  }
`;

const PodiumContainer = styled.div`
  svg {
    ${breakpoints.down(breakpoints.tablet)} {
      // display: none;
      transform: scale(0.55);
    }
  }
`;

const WinnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: ${props => props.justifySelf || null};
  margin: 0;
  margin-top: ${props => props.top || null};
`;

// const FirstHead = styled.p`
//   margin-bottom: 10px;
//   font-size: 24px;
//   font-weight: 500;
//   line-height: 29px;
//   letter-spacing: 0px;
// `;

const PositionHeader = styled.p`
  margin-bottom: 10px;
  color: ${props => props.color || "#f5bc46"};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
`;

const WinnerPosIconContainer = styled.div`
  padding-bottom: 10px;
`;

const WinnerName = styled.p`
  padding-top: 5px;
  color: #ffffff;
  font-style: italic;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  margin-bottom: 0;
  white-space: nowrap;
`;

const WinnerScore = styled.p`
  color: #be7eff;
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  margin-bottom: 5px;
`;

const CupPodiumContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 130px;
`;

const CupPodiumHead = styled.p`
  font-size: ${props => props.fontSize || null};
  color: ${props => props.color || null};
  margin-bottom: ${props => props.marginBottom || null};
  width: ${props => props.width || null};
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`;

const FinalistTable = styled.div`
  min-height: 0px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #7265c8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

const FinalistContainer = styled.div`
  width: 100%;
  /* border: 2px solid red; */
  /* height: 200px; */
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  margin-top: 10px;
  padding: 10px 10px;
  min-height: 60px;
  background: #3f2faa;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
  :nth-child(1) {
    margin-top: 0;
  }
`;

const HeadingsContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
  :nth-child(1) {
    margin-top: 0;
  }
`;

const PositionHeading = styled.div`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
`;
const UserDataHeading = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
`;

const ScoreHeading = styled.div`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
  @media only screen and (max-width: 528px) {
    display: none;
  }
`;
const PercentageHeading = styled.div`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
  @media only screen and (max-width: 528px) {
    display: none;
  }
`;

const PrizeHeading = styled.div`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
`;

const FinalistPosition = styled.p`
  width: 62.15px;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 350;
  margin-bottom: 0;
`;

const UserData = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

const FinalistName = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: 350;
  margin-bottom: 0;
`;

const FinalistScore = styled.p`
  width: 65.42px;
  font-size: 14px;
  font-weight: 350;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: right;
  color: #be7eff;
  margin-bottom: 0;
  justify-self: end;
  @media only screen and (max-width: 528px) {
    display: none;
  }
`;

const FinalPrize = styled.p`
  width: 65.42px;
  font-size: 14px;
  font-weight: 350;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: right;
  color: #be7eff;
  margin-bottom: 0;
  justify-self: end;
  font-size: 16px;
  color: #fff;
  font-weight: 350;
`;

const FinalPercentage = styled.p`
  width: 65.42px;
  font-size: 14px;
  font-weight: 350;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: right;
  color: #be7eff;
  margin-bottom: 0;
  justify-self: end;
  font-size: 14px;
  color: #ebebf5;
  @media only screen and (max-width: 528px) {
    display: none;
  }
`;

const PlayerViewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 10px;
`;

const PlayerContainer = styled.div`
  width: 100%;
  height: 60px;
  background-color: #342788;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 25px;

  &:hover {
    cursor: ${props => (props.joinMode !== "TEAM" ? "default" : "pointer")};
  }
`;

const PlayerName = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  color: #ffffff;
  margin: 0;
`;

const WinnerCardsContainer = styled.div`
  display: flex;
  column-gap: 95px;
  row-gap: 45px;
  justify-content: center;
  flex-wrap: wrap;
  width: ${props => (props.dataLen < 5 ? "100%" : "85%")};
  margin: auto;
  margin-top: 87px;
  margin-bottom: 78px;
`;

const WinnerCardBg = styled.div`
  background: ${props => (props.bgImg ? `url(${props.bgImg})` : `url(${BG})`)};
  box-shadow: 0px 100px 100px #201366 inset, #201366 0px -100px 100px -28px inset;
  margin-bottom: 80px;
  margin-top: 40px;
  min-height: 500px;
  width: 100vw;
  margin-left: -103px;
  position: absolute;
  background-size: cover;

  @media only screen and (min-width: 1650px) {
    margin-left: 0;
    width: 1200px;
  } /* margin-left: -103px; */
`;

export function WinnersComponent({ data = [], isFinished, winnersPrize = [], winnerSectionBg = "" }) {
  if (winnersPrize.length > 0) {
    const tablePosHolder = winnersPrize.length < 3 ? data.slice(2, data.length) : data.slice(3, data.length);
    const positionToString = position => {
      const positionArray = position?.toString()?.split("");
      const lastDigit = positionArray?.[positionArray.length - 1];
      if (position !== 11 && lastDigit === "1") {
        return `${position}st`;
      }
      if (position !== 12 && lastDigit === "2") {
        return `${position}nd`;
      }
      if (position !== 13 && lastDigit === "3") {
        return `${position}rd`;
      }
      return `${position}th`;
    };

    return (
      <div>
        {isFinished ? (
          <>
            <>
              <WinnerCardBg className="winner-bg" bgImg={winnerSectionBg} />
              <WinnerCardsContainer dataLen={winnersPrize?.length}>
                {winnersPrize?.slice(0, 5)?.map(winnerItem => (
                  <WinnerCard {...winnerItem} />
                ))}
              </WinnerCardsContainer>
            </>
            {winnersPrize?.length > 5 ? (
              <>
                <HeadingsContainer>
                  <PositionHeading>Position</PositionHeading>
                  <UserDataHeading>
                    <div>Username</div>
                  </UserDataHeading>
                  <ScoreHeading>Score</ScoreHeading>
                  <PercentageHeading>Percentage</PercentageHeading>
                  <PrizeHeading>Prize</PrizeHeading>
                </HeadingsContainer>
                <FinalistTable>
                  {winnersPrize?.slice(5).map(player => (
                    <FinalistContainer>
                      <FinalistPosition>{positionToString(player?.position)}</FinalistPosition>
                      <UserData>
                        <Avatar size="34px" src={player?.avatarUrl} alt="" />
                        <FinalistName>{player?.nick}</FinalistName>
                      </UserData>
                      <FinalistScore>{Math.round(player?.score) || 0}</FinalistScore>
                      {player?.percentage ? (
                        <FinalPercentage>{Math.round(player?.percentage)}%</FinalPercentage>
                      ) : (
                        <FinalPercentage>-</FinalPercentage>
                      )}
                      {player?.usdAmount ? (
                        <FinalPrize>${Math.round(player?.usdAmount)}</FinalPrize>
                      ) : (
                        <FinalPrize>-</FinalPrize>
                      )}
                    </FinalistContainer>
                  ))}
                </FinalistTable>
              </>
            ) : null}
          </>
        ) : (
          <CupPodiumContainer>
            <div>
              <CupPodiumNew />
            </div>
            <CupPodiumHead fontSize="20px" color="#D1CCEF" marginBottom="15px">
              The tournament hasn&apos;t finished yet
            </CupPodiumHead>
            <CupPodiumHead fontSize="14px" color="#877ADD" width="289px">
              Once the tournament is over, the data takes a few minutes to appear.
            </CupPodiumHead>
          </CupPodiumContainer>
        )}
      </div>
    );
  } else {
    return (
      <CupPodiumContainer>
        <div>
          <CupPodiumNew />
        </div>
      </CupPodiumContainer>
    );
  }
}

function LeaderBoardComponent({
  leaderBoardInfo,
  isMobile,
  gamePlatform,
  joinMode,
  selectPlayerForTemplate,
  hasTemplate,
  groupIdSelected

}) {
  return leaderBoardInfo.length > 0 ? (
    <>
      {!isMobile && (
        <S.RowHeaderWrapper>
          <S.HeaderText>Ranking</S.HeaderText>
          <S.HeaderText>Nick</S.HeaderText>
          <S.HeaderTextRightAlign>Top Score</S.HeaderTextRightAlign>
          <S.HeaderTextRightAlign>Top Kills</S.HeaderTextRightAlign>
          <S.HeaderTextRightAlign>Team Pos.</S.HeaderTextRightAlign>
          <S.HeaderTextRightAlign>K/D</S.HeaderTextRightAlign>
          {gamePlatform === "pubg" ? (
            <S.HeaderTextRightAlign>Damage</S.HeaderTextRightAlign>
          ) : (
            <S.HeaderTextRightAlign>Damage D/T</S.HeaderTextRightAlign>
          )}
        </S.RowHeaderWrapper>
      )}
      <Pagination
        data={leaderBoardInfo}
        isMobile={isMobile}
        RenderComponent={props => (
          <PlayerRow
            {...props}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}
            groupIdSelected={groupIdSelected}
          />
        )}
        pageLimit={isMobile ? Number(MOBILE_LEADERBOARD_PAGE_LIMIT) || 3 : Number(LEADERBOARD_PAGE_LIMIT) || 10}
        dataLimit={Number(LEADERBOARD_ROWS) || 5}
      />
    </>
  ) : (
    <S.ImageWrapper>
      <img src={images[randomIndex]} alt="" />
    </S.ImageWrapper>
  );
}

function KoinsLeaderboard({
  koinsLeaderboardHeaders,
  leaderBoardInfo,
  isMobile,
  gamePlatform,
  joinMode,
  selectPlayerForTemplate,
  hasTemplate,
}) {
  const koinsLeaderboardData = leaderBoardInfo.map(data => ({ ...data, dynamicHeaders: koinsLeaderboardHeaders }));
  return koinsLeaderboardHeaders.length > 0 ? (
    <>
      {!isMobile && (
        <S.RowHeaderWrapper colCount={koinsLeaderboardHeaders.length + 1}>
          <S.HeaderText>Ranking</S.HeaderText>
          {koinsLeaderboardHeaders.map(item => (
            <S.HeaderText>{item}</S.HeaderText>
          ))}
        </S.RowHeaderWrapper>
      )}
      <Pagination
        data={koinsLeaderboardData}
        isMobile={isMobile}
        RenderComponent={props => (
          <PlayerRow
            {...props}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}
          />
        )}
        pageLimit={isMobile ? Number(MOBILE_LEADERBOARD_PAGE_LIMIT) || 3 : Number(LEADERBOARD_PAGE_LIMIT) || 10}
        dataLimit={Number(LEADERBOARD_ROWS) || 5}
      />
    </>
  ) : (
    <S.ImageWrapper>
      <img src={images[randomIndex]} alt="" />
    </S.ImageWrapper>
  );
}

export const PlayerComponent = ({ data = [], template, joinMode }) => {
  const [showLeaderboardTemplate, setShowLeaderboardTemplate] = useState(false);
  const [teamData, setTeamData] = useState(null);

  function toggleLeaderboardTemplate() {
    setShowLeaderboardTemplate(prev => !prev);
  }

  if (data.length > 0) {
    return (
      <PlayerViewContainer>
        {data.map(playerObject => (
          <PlayerContainer
            key={playerObject}
            onClick={() => {
              toggleLeaderboardTemplate();
              setTeamData(playerObject?.item);
            }}
            joinMode={joinMode}
          >
            <Avatar src={playerObject?.item?.avatarURL} size="40px" />
            <PlayerName>{playerObject?.item?.nick}</PlayerName>
          </PlayerContainer>
        ))}
        {joinMode === "TEAM" ? (
          <LeaderboardTemplateModal
            toggleModal={toggleLeaderboardTemplate}
            showModal={showLeaderboardTemplate}
            template={template}
            team={teamData}
          />
        ) : null}
      </PlayerViewContainer>
    );
  } else {
    return <div />;
  }
};

function LeaderBoardActiveTab({
  activeFilter,
  leaderBoardInfo = [],
  isFinished,
  koinsLeaderboardHeaders,
  isMobile,
  gamePlatform,
  gameId,
  dynamicHeaders,
  joinMode,
  winnersPrize,
  selectPlayerForTemplate,
  hasTemplate,
  template,
  winnerSectionBg,
  searchTerm = "",
  poolStatus,
  poolId,
  groupIdSelected
}) {
  const debouncedSearch = useDebounce(searchTerm, 200);
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    if (!debouncedSearch) {
      setFilteredList(leaderBoardInfo);
      return;
    }
    setFilteredList(
      leaderBoardInfo?.filter(player =>
        player.item?.nick?.toLowerCase()?.includes(debouncedSearch?.toLowerCase()?.trim()),
      ),
    );
  }, [debouncedSearch]);

  useEffect(() => {
    if(!leaderBoardInfo?.length) {
      setFilteredList([])
    }
    if (leaderBoardInfo && Array.isArray(leaderBoardInfo) && leaderBoardInfo.length > 0) {
      setFilteredList(leaderBoardInfo);
    }
  }, [leaderBoardInfo.length]); // using leaderboardInfo.length instead of leaderboardInfo to prevent unnecessary rerenders

  switch (activeFilter) {
    case "Players":
    case "Teams":
      // return <PlayerComponent data={filteredList} isFinished={isFinished} template={template} joinMode={joinMode} />;
      return (
        <BracketsPlayers
          joinMode={joinMode}
          players={filteredList.map(listItem => ({...listItem?.item || {}}))}
          poolId={poolId}
          poolStatus={poolStatus}
        />
      );
    case "Winners":
      return (
        <WinnersComponent
          data={leaderBoardInfo}
          isFinished={isFinished}
          winnersPrize={winnersPrize}
          winnerSectionBg={winnerSectionBg}
        />
      );
    case "All":
    default:
      return (koinsLeaderboardHeaders && koinsLeaderboardHeaders?.length > 0) ||
        (dynamicHeaders && dynamicHeaders?.length > 0) ? (
        gameId == KOINS_GAME_ID ? (
          <KoinsLeaderboard
            koinsLeaderboardHeaders={koinsLeaderboardHeaders}
            leaderBoardInfo={filteredList}
            isMobile={isMobile}
            gamePlatform={gamePlatform}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}
          />
        ) : (
          <DynamicLeaderboard
            dynamicHeaders={dynamicHeaders}
            leaderBoardInfo={filteredList}
            isMobile={isMobile}
            gameId={gameId}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}
            groupIdSelected={groupIdSelected}
          />
        )
      ) : (
        <LeaderBoardComponent
          leaderBoardInfo={filteredList}
          isMobile={isMobile}
          gamePlatform={gamePlatform}
          joinMode={joinMode}
          selectPlayerForTemplate={selectPlayerForTemplate}
          hasTemplate={hasTemplate}
          groupIdSelected={groupIdSelected}
        />
      );
  }
}

export default function LeaderBoard({
  leaderBoardData,
  isFinished,
  isFinishedCollectingData,
  gamePlatform,
  koinsLeaderboardHeaders,
  gameId,
  dynamicHeaders,
  joinMode,
  winnersPrize,
  template,
  winnerSectionBg = "",
  status,
  poolType,
  organizerInfo = [],
  poolId,
  groupIdSelected
}) {
  const isMobile = useMedia({ maxWidth: breakpoints.laptop });
  const leaderBoardInfo = leaderBoardData;
  const [filterArray, setFilterArray] = useState([
    "Leaderboard",
    joinMode !== "TEAM" ? "Players" : "Teams",
    "Contact Info",
  ]);
  const [activeFilter, setActiveFilter] = useState(poolType === "MULTI_STAGE" ? "Leaderboard" : "");
  const [showLeaderboardTemplate, setShowLeaderboardTemplate] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  function filterItems(item) {
    setActiveFilter(item);
  }

  function selectPlayerForTemplate(data) {
    setSelectedPlayer(data);
    setShowLeaderboardTemplate(true);
  }

  function toggleLeaderboardTemplate() {
    setShowLeaderboardTemplate(prev => !prev);
  }

  const handleClear = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    if (isFinished && winnersPrize?.length >= 1) {
      const newSectionsMap = [...filterArray]; // Create a copy of the original array
      const playersIndex = newSectionsMap?.indexOf("Players" || "Teams");

      // Insert "Winners" after "Players"
      newSectionsMap.splice(playersIndex, 0, "Winners");

      // Update state with the modified array
      setFilterArray(newSectionsMap);

      // Update the selected section
      setActiveFilter("Winners");
    } else if (status === "JOINING" || status === "PROCESSING_REGISTERED_USERS") {
      setActiveFilter(joinMode !== "TEAM" ? "Players" : "Teams");
    } else {
      setActiveFilter("Leaderboard");
    }
  }, [isFinished]);

  //

  // {poolType !== "MULTI_STAGE" && (
  //   <div>
  //     <TopMenu>
  //       <FilterWrapper>
  //         <FilterButtons filterOfButtons={filterArray} activeFilter={activeFilter} func={filterItems} />
  //       </FilterWrapper>
  //     </TopMenu>
  //   </div>
  // )}
  // <GroupSelector />
  // <LeaderBoardActiveTab
  //   activeFilter={activeFilter}
  //   leaderBoardInfo={leaderBoardInfo}
  //   isFinished={isFinished}
  //   koinsLeaderboardHeaders={koinsLeaderboardHeaders}
  //   dynamicHeaders={dynamicHeaders}
  //   isMobile={isMobile}
  //   gamePlatform={gamePlatform}
  //   gameId={gameId}
  //   joinMode={joinMode}
  //   winnersPrize={winnersPrize}
  //   selectPlayerForTemplate={selectPlayerForTemplate}
  //   hasTemplate={template && Object.keys(template)?.length > 0}
  //   template={template}
  //   winnerSectionBg={winnerSectionBg}
  // />

  //
  return (
    <>
      <S.LeaderBoardContainer isMobile={isMobile}>
        {poolType !== "MULTI_STAGE" && (
          <Flex justifyContent="space-between" alignItems="center" extraStyles={{ marginBottom: "45px" }}>
            <TopMenu>
              <FilterWrapper>
                {filterArray.map(item => (
                  <Tooltip
                    title={item == "Contact Info" && organizerInfo?.length < 1 ? `Organizer info not available` : ""}
                    color="#7265C8"
                    overlayStyle={{
                      maxWidth: "fit-content",
                      fontSize: "11px",
                      padding: "11px 8px",
                      borderRadius: "6px",
                    }}
                  >
                    <FilterButton
                      active={activeFilter === item}
                      shouldDisable={
                        item == "Contact Info" && (!organizerInfo || organizerInfo?.length < 1)
                      }
                      key={item}
                      onClick={() => {
                        if (item === "Contact Info" && organizerInfo?.length < 1) return;
                        filterItems(item);
                      }}
                    >
                      {item}
                    </FilterButton>
                  </Tooltip>
                ))}
              </FilterWrapper>
            </TopMenu>
            {activeFilter === "Leaderboard" || activeFilter === "Teams" ? (
              <S.PlayerSearchContainer>
                <S.PlayerSearchInput
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target?.value)}
                  placeholder="Search"
                  type="search"
                />
                <S.ClearButton onClick={handleClear} visible={!!searchTerm}>
                  <CloseIcon />
                </S.ClearButton>
              </S.PlayerSearchContainer>
            ) : null}
          </Flex>
        )}
        {activeFilter === "Contact Info" ? (
          <ContactSection organizerInfo={organizerInfo} />
        ) : (
          <>
            {/* <GroupSelector /> */}
            <LeaderBoardActiveTab
              activeFilter={activeFilter}
              leaderBoardInfo={leaderBoardInfo}
              isFinished={isFinished}
              koinsLeaderboardHeaders={koinsLeaderboardHeaders}
              dynamicHeaders={dynamicHeaders}
              isMobile={isMobile}
              gamePlatform={gamePlatform}
              gameId={gameId}
              joinMode={joinMode}
              winnersPrize={winnersPrize}
              selectPlayerForTemplate={selectPlayerForTemplate}
              hasTemplate={template && Object.keys(template)?.length > 0}
              template={template}
              winnerSectionBg={winnerSectionBg}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              poolStatus={status}
              poolId={poolId}
              groupIdSelected={groupIdSelected}
            />
          </>
        )}
      </S.LeaderBoardContainer>

      <LeaderboardTemplateModal
        toggleModal={toggleLeaderboardTemplate}
        showModal={showLeaderboardTemplate}
        team={selectedPlayer}
        template={template}
      />
    </>
  );
}
