/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import useMedia from "use-media";
import * as S from "./styles";
import { breakpoints } from "../../../../themes";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatDateTime } from "../../../../helpers/utils";
import { GreenCheckMark } from "../../../../assets/svg";
import { useGetSecHeaders } from "../../../../hooks";
import { BE_API_BASE_URL } from "../../../../constants/env";
import useCallAxios from "../../../../hooks/useAxiosCall";

export default function Round({
  roundIndex,
  roundsCount,
  startDate,
  endDate,
  pageCount,
  status,
  playersQty,
  userPositionInfo,
  headers,
  data,
  roundId,
  pageId,
  branchId,
  poolId,
  setIsLoading,
  getPoolData,
  userIsPlaying,
  lastPage,
  poolMode,
  joinMode,
  tournamentMode,
  stageId,
}) {
  let currentPlayerQty = playersQty;
  for (let i = 1; i <= roundIndex + (pageCount - 1) * 4; i++) {
    if (!currentPlayerQty) break;
    currentPlayerQty = currentPlayerQty / 2;
  }

  const [userCheckedIn, setUserCheckedIn] = useState(false);
  const [userMatchId, setUserMatchId] = useState("");

  const { callAxiosFunc } = useCallAxios();

  useEffect(() => {
    if (!headers.userId) return;
    if (data && data.seeds) {
      if (joinMode === "TEAM") {
        data.seeds.map(seedData => {
          const firstTeamCaptain = seedData?.teams[0]?.teamMembers?.find(member => member.role === "captain");
          const secondTeamCaptain = seedData?.teams[1]?.teamMembers?.find(member => member.role === "captain");

          // TODO: [changeToUserId] replacing with userId. Ensure property name from backend
          if (firstTeamCaptain && firstTeamCaptain?.userId == headers?.userId) {
            setUserCheckedIn(seedData.teams[0]?.isCheckedIn);
            setUserMatchId(seedData?.id);
            // TODO: [changeToUserId] replacing with userId. Ensure property name from backend
          } else if (secondTeamCaptain && secondTeamCaptain?.userId == headers?.userId) {
            setUserCheckedIn(seedData.teams[1]?.isCheckedIn);
            setUserMatchId(seedData?.id);
          }
        });
      } else {
        data.seeds.map(seedData => {
          // TODO: [changeToUserId] replacing with userId. Ensure property name from backend
          if (seedData?.teams[0]?.playerId == headers?.userId) {
            setUserCheckedIn(seedData.teams[0]?.isCheckedIn);
            setUserMatchId(seedData?.id);
            // TODO: [changeToUserId] replacing with userId. Ensure property name from backend
          } else if (seedData?.teams[1]?.playerId == headers?.userId) {
            setUserCheckedIn(seedData.teams[1]?.isCheckedIn);
            setUserMatchId(seedData?.id);
          }
        });
      }
    }
  }, [data, headers.userId]);

  const checkInFunc = async () => {
    if (!userMatchId) return;
    setIsLoading(true);
    try {
      const response = await callAxiosFunc({
        method: "POST",
        url: `${BE_API_BASE_URL}poolData/checkIn/${poolId}/${tournamentMode === "MULTI_STAGE" ? stageId : "0"}/${pageId}/${branchId}/${roundId}/${userMatchId}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      getPoolData();
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const checkPoolMode = poolMode => {
    switch (poolMode) {
      case "BEST_OF_3":
        return "BO3";
      case "BEST_OF_5":
        return "BO5";
      case "BEST_OF_7":
        return "BO7";
      case "BEST_OF_9":
        return "BO9";
      case "SINGLE_ELIMINATION":
      case "BEST_OF_1":
        return "BO1"
      default:
        return `${poolMode}`;
    }
  };

  return (
    <S.Container>
      <S.RoundCard
        index={roundIndex}
        finalRect={roundIndex === roundsCount - 1 && !lastPage}
        active={status === "ACTIVE"}
        userInPool={headers?.token && userPositionInfo}
      >
        <S.InfoWrapper>
          <S.RoundNumber active={status === "ACTIVE"}>
            <p>Round {roundIndex + 1 + (pageCount - 1) * 4}</p>
            {status === "CLOSED" && <S.Status status={status}>FINISHED</S.Status>}
            {status === "ACTIVE" && <S.Status status={status}>ACTIVE</S.Status>}
          </S.RoundNumber>
          <S.RoundFormatWrapper>{checkPoolMode(poolMode)}</S.RoundFormatWrapper>
        </S.InfoWrapper>
        <S.RoundDate>{formatDateTime(startDate)}</S.RoundDate>
        <S.PlayerCount active={status === "ACTIVE"}>{Math.round(currentPlayerQty) || 0} Players</S.PlayerCount>
        <S.CenterContainer>
          {status == "ACTIVE" &&
            headers?.token &&
            userIsPlaying &&
            userPositionInfo &&
            userPositionInfo?.pages?.some(item => item.id === pageId && item.branch === branchId) && (
              <S.CheckinButton checkedIn={userCheckedIn}>
                {userCheckedIn ? (
                  <div>
                    You're all set <GreenCheckMark style={{ marginLeft: "8px" }} />
                  </div>
                ) : (
                  <div onClick={checkInFunc}>Check in</div>
                )}
              </S.CheckinButton>
            )}
        </S.CenterContainer>
      </S.RoundCard>
      <S.ExtraRect>
        {roundIndex === roundsCount - 1 &&
          roundsCount < 4 &&
          [...Array(4 - roundsCount).keys()].map((_, i, arr) => {
            return <S.RoundCard finalRect={i === arr.length - 1} />;
          })}
      </S.ExtraRect>
    </S.Container>
  );
}
