import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { breakpoints } from "../themes";
import { ENDPOINT } from "../constants/endpoints";
import styled from "styled-components";
import callAxios from "../hooks/Axios";
import TopWinnersList from "../components/v2/Home/TopWinners/TopWinnersList";
import TournamentList from "../components/v2/Home/PoolTournamentList/List";
import Flex from "../components/v2/common/Flex";
import useCallAxios from "../hooks/useAxiosCall";
import { selectedGameState } from "../recoil/gameState";
import { useRecoilState } from "recoil";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
  margin-bottom: 100px;
`;

const BannerContainer = styled.div`
  width: 100%;
  height: 240px;
  border-radius: 10px;
  display: flex;
  background: linear-gradient(270.02deg, rgba(0, 0, 0, 0) 50%, #5431dc 100%), url(${props => props.bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    background: linear-gradient(270.02deg, rgba(0, 0, 0, 0) 50%, #5431dc 100%), url(${props => props.bgImage});
  }
`;

const GameTitle = styled.div`
  height: 100%;
  padding-left: 44px;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  margin: 0;
  padding: 0;

  ${breakpoints.down(breakpoints.tablet)} {
    font-size: 30px;
    font-weight: 600;
    line-height: 28px;
  }
`;

const SubTitle = styled.h2`
  color: #be7eff;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 50px;
`;

const ActiveTournamentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

const TournamentsTitle = styled.h1`
  align-self: flex-start;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 20px;
  ${breakpoints.down(breakpoints.tablet)} {
    font-size: 20px;
    font-weight: 325;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 10px;
  }
`;

const TournamentListContainer = styled.div`
  width: 100%;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

const SelectorButtonsTournaments = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding-top: 30px;
`;

const TopWinnersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: center;
  margin-bottom: 70px;

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

const TopWinnersTitle = styled.p`
  align-self: flex-start;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 20px;
  ${breakpoints.down(breakpoints.tablet)} {
    font-size: 26px;
    font-weight: 325;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 10px;
  }
`;

const Button = styled.button`
  height: 40px;
  width: 100px;
  border-radius: 30px;
  border: none;
  background: ${props =>
    props.isActive === true ? "linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%)" : "#3f2faa"};

  opacity: ${props => (props.disabled === true ? "0.5" : "1")};
  color: #ffffff;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
  & div {
    margin-left: 10px;
    margin-right: 10px;
  }
  &:hover {
    margin-top: -2px;
    cursor: ${props => (props.disabled === true ? "not-allowed" : "pointer")};
    box-shadow: 0px 12px 30px 0px rgba(26, 14, 86, 1);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  cursor: pointer;
`;

const Logo = styled.img`
  height: 80px;
`;

export default function GamePage() {
  const { id } = useParams();
  const [ImageArray, setImageArray] = useState([]);
  const [filterArray, setFilterArray] = useState([
    { name: "All", dataTestId: "all-button" },
    { name: "Active", dataTestId: "active-button" },
    { name: "Upcoming", dataTestId: "upcoming-button" },
    { name: "Finished", dataTestId: "finished-button" },
  ]);

  const [activeFilter, setActiveFilter] = useState(filterArray[0].name);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [gameLogo, setGameLogo] = useState("");
  const [topWinners, setTopWinners] = useState([]);
  const [tournamentStreaming, setTournamentStreaming] = useState([]);

  const [selectedGame, setSelectedGame] = useRecoilState(selectedGameState);

  const { callAxiosFunc } = useCallAxios();


  /**
   * It takes the game ID, makes a call to an API, and then sets the state of an array with the images.
   */
  const getGameBanner = async () => {
    setImageArray([]);
    const getGameBannerCall = await callAxiosFunc({
      method: "POST",
      url: `${ENDPOINT.ADVERTISEMENT_GAME}`,
      body: JSON.stringify({ advertisementValues: [id] }),
    });
    const getGameBannerResponse = getGameBannerCall?.data?.data;
    getGameBannerResponse.map(item => {
      setImageArray(item.largeImage);
    });
  };

  const getGameData = async () => {
    const getGameNameCall = await callAxiosFunc({
      method: "GET",
      url: `${ENDPOINT.GAME}/${id}`,
    });
    setSelectedGame(getGameNameCall?.data?.data?.id);
    setName(getGameNameCall?.data?.data?.name);
    setGameLogo(getGameNameCall?.data?.data?.assets?.gameLogo);
  };

  const getStreams = async ({ poolId }) => {
    const response = await callAxiosFunc({
      method: "GET",
      url: `${ENDPOINT.GET_STREAM}?poolsId=${poolId}`,
      headers: JSON.stringify({ accept: "*/*" }),
    });
    if (response.err) {
      console.log(response.err);
    } else {
      setTournamentStreaming(response?.data?.data);
    }
  };

  // variables used to setup the api calls based on tournament status
  const Filters = {
    ACTIVE: "Active",
    ALL: "All",
    UPCOMING: "Upcoming",
    FINISHED: "Finished",
  };

  // states used to store the data of the api calls
  const [tournamentListByGame, setTournamentListByGame] = useState([]);

  const getPools = async status => {
    setIsLoading(true);
    const poolsApiCall = await callAxiosFunc({
      method: "POST",
      url: `${ENDPOINT.POOL_LIST_BY_GAME}/${id}`,
      body: JSON.stringify({
        poolStatus:
          status === Filters.ALL
            ? ""
            : status === Filters.ACTIVE
            ? "active"
            : status === Filters.UPCOMING
            ? "upcoming"
            : status === Filters.FINISHED
            ? "finished"
            : null,
      }),
    });
    if (poolsApiCall && poolsApiCall.err) {
      setTournamentListByGame([]);
    } else {
      setTournamentListByGame(poolsApiCall?.data?.data);
    }
    setIsLoading(false);
  };

  const getTopWinners = async () => {
    try {
      const getTopWinnersApiCall = await callAxios({
        method: "GET",
        url: `${ENDPOINT.TOP_WINNERS}/${id}`,
      });
      const apiResponse = getTopWinnersApiCall?.data?.data?.topPrizers;
      if (apiResponse.length > 0) {
        //based on any screen resolution we are showing n top winners that completes the row, each card has a width of 205px and gap of 27px, so we need to complete 1fr row (it needs just to be one row), the amount of cards returned it must generate just one row of cards
        // window.screen.width must be the screen width minus the width of both sidebars
        // 205 is the width of the card
        // 27 is the gap between cards
        // 1fr is the width of the row
        // 91px is the width of one of the sidebars
        // 87px is the width of the other sidebar
        // the container has a padding-right of 72px and a left one of 47px
        const amountOfCards = Math.floor((window.screen.width - 91 - 87 - 72 - 47) / (205 + 27));
        if (amountOfCards > 0 && apiResponse.length > 0) {
          const topWinnersArray = apiResponse.slice(0, amountOfCards);
          setTopWinners(topWinnersArray);
        } else {
          setTopWinners([]);
        }
      } else {
        setTopWinners([]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onClickFilter = item => {
    setTournamentListByGame([]);
    setActiveFilter(item);
    getPools(item);
  };

  // use location of react-router-dom to get the page used, resets the content of the states and calls the functions
  const location = useLocation();
  useEffect(() => {
    setActiveFilter(filterArray[0].name);
    getGameData();
    getGameBanner();
    getPools(filterArray[0].name);
    getTopWinners();
  }, [location]);

  useEffect(() => {
    if (tournamentListByGame) {
      const extractedPoolIds = tournamentListByGame?.map(pool => pool?.id)?.join(",");
      getStreams({ poolId: extractedPoolIds });
    }
  }, [tournamentListByGame])

  return (
    <Flex alignItems="center" justifyContent="center" data-testid="gamepage-container">
      <Container>
        <BannerContainer bgImage={ImageArray}>
          <GameTitle>
            <Logo src={gameLogo} />
          </GameTitle>
        </BannerContainer>
        <ActiveTournamentsContainer data-testid="tournaments-container">
          <TournamentsTitle>Tournaments</TournamentsTitle>
          <Flex direction="column" rowGap="20" extraStyles={{ width: "100%" }}>
            <SelectorButtonsTournaments data-testid="button-container">
              {filterArray.map(item => (
                <Button
                  disabled={isLoading}
                  data-testid={item.dataTestId}
                  isActive={activeFilter === item.name}
                  onClick={() => {
                    if (activeFilter === item.name) return;
                    onClickFilter(item.name);
                  }}
                >
                  {item.name}
                </Button>
              ))}
            </SelectorButtonsTournaments>
            <TournamentListContainer data-testid="tournament-list">
              <TournamentList activeFilter={activeFilter} isLoading={isLoading} tournamentList={tournamentListByGame} streamerData={tournamentStreaming} />
            </TournamentListContainer>
          </Flex>
        </ActiveTournamentsContainer>
        {topWinners.length > 0 && (
          <TopWinnersContainer>
            <TopWinnersTitle>Top Winners</TopWinnersTitle>
            <TopWinnersList topWinners={topWinners} />
          </TopWinnersContainer>
        )}
      </Container>
    </Flex>
  );
}
