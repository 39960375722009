import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TournamentList from "../components/v2/Tournament/TournamentList";
import useTournamentList, { useTournamentListQuery } from "../hooks/TournamentsList";
import { useGetSecHeaders } from "../hooks/SecurityHeaders";
import { DottedMenu } from "../assets/svg";
import adImg from "../assets/tournament-list-page-ad.png";
import MobileSocialNav from "../components/v2/common/MobileSocialNav";
import { breakpoints } from "../themes";
import useCallAxios from "../hooks/useAxiosCall";
import { ENDPOINT } from "../constants/endpoints";

const TournamentListPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${breakpoints.down(breakpoints.tablet)} {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AdWrapper = styled.div`
  position: absolute;
  padding-left: 30px;
  top: -108px;
  width: 100%;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  justify-content: space-between;
  ${breakpoints.down(breakpoints.laptopL)} {
    img {
      margin-top: 80px;
      width: 700px;
      height: 350px;
      transform: translateX(50%);
    }
  }

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    img {
      width: 800px;
      height: 400px;
      transform: translateX(0%);
    }
  }

  @media (max-width: 580px) {
    img {
      width: 800px;
      height: 400px;
      transform: translateX(-10%);
    }
  }
  @media (max-width: 500px) {
    img {
      width: 800px;
      height: 400px;
      transform: translateX(-35%);
    }
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 0px 40px;
  padding-bottom: 70px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 100px;
  ${breakpoints.down(breakpoints.tablet)} {
    padding-bottom: 128px;
    width: 100%;
    padding: 0 23px;
  }
`;

const HeaderTitle = styled.p`
  font-size: 26px;
  line-height: 35px;
  color: white;
  margin-bottom: 19px;

  ${breakpoints.down(breakpoints.tablet)} {
    position: absolute;
    top: -265px;
    width: 180px;
  }
`;

const FilterWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
  gap: 10px;
  padding-bottom: 23px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const DottedMenuContainer = styled.div`
  position: relative;
`;

const MenuContent = styled.div`
  background-color: #3f2faa;
  width: 233px;
  position: absolute;
  right: 0;
  padding: 11px 21px;
  border-radius: 6px;
  color: #fff;
  div {
    margin: 0px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #be7eff;
    }
  }
`;

const TopMenu = styled.div`
  padding-bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

const DottedMenuButton = styled.button`
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
`;

const AdTextWrapper = styled.div`
  position: absolute;
  top: 101px;

  & > p {
    color: white;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 0px;
    ${breakpoints.down(breakpoints.tablet)} {
      font-size: 30px;
      font-weight: 325;
      line-height: normal;
    }
  }

  & span {
    color: #be7eff;
  }

  ${breakpoints.down(breakpoints.tablet)} {
    top: 115px;
  }
`;

const Button = styled.button`
  height: 40px;
  padding: 0 30px;
  border-radius: 30px;
  border: none;
  background: ${props =>
    props.isActive === true ? "linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%)" : "#3f2faa"};
  color: #ffffff;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  text-align: center;
  &:hover {
    margin-top: -2px;
    margin-bottom: 2px;
    cursor: pointer;
    box-shadow: 0px 12px 30px 0px rgba(26, 14, 86, 1);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:disabled {
    cursor: not-allowed;
    color: #ffffffcc;
  }
  cursor: pointer;
`;

const TournamentListPage = React.memo(() => {
  const sideMenuRef = useRef({});
  const headers = useGetSecHeaders();
  const [filterArray, setFilterArray] = useState(["Upcoming", "Active", "Finished", "All"]);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showFinishedTournaments, setShowFinishedTournaments] = useState(false);

  const [tournamentStreaming, setTournamentStreaming] = useState([]);

  const { callAxiosFunc } = useCallAxios();

  const getStreams = async ({ poolId }) => {
    const response = await callAxiosFunc({
      method: "GET",
      url: `${ENDPOINT.GET_STREAM}?poolsId=${poolId}`,
      headers: JSON.stringify({ accept: "*/*" }),
    });
    if (response.err) {
      console.log(response.err);
    } else {
      setTournamentStreaming(response?.data?.data);
    }
  };

  useEffect(() => {
    //  The following `if` block checks whether user is logged in (by checking token)
    //  and ensures that multiple `My Tournaments` tabs are not added
    if (headers.token && filterArray.findIndex(item => item === "My Tournaments") === -1) {
      setFilterArray([...filterArray, "My Tournaments"]);
    }
  }, [headers.token, filterArray]);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showSideMenu && sideMenuRef.current && !sideMenuRef.current.contains(e.target)) {
        setShowSideMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSideMenu]);

  const [activeFilter, setActiveFilter] = useState(filterArray[0]);

  const [tournamentListState, setTournamentListState] = useState([]);

  const onClickFilter = item => {
    // setTournamentListState([]);
    setActiveFilter(item);
  };
  // const { tournamentList, isLoading } = useTournamentList(activeFilter, !showFinishedTournaments);
  const { tournamentList, isLoading, isFetching } = useTournamentListQuery(activeFilter, !showFinishedTournaments);

  useEffect(() => {
    if (tournamentList) {
      setTournamentListState(tournamentList);
      const extractedPoolIds = tournamentList?.map(pool => pool?.id)?.join(",");
      getStreams({ poolId: extractedPoolIds });
    }
  }, [tournamentList]);

  return (
    <TournamentListPageWrapper>
      <AdWrapper>
        <AdTextWrapper>
          <p>Tournaments</p>
        </AdTextWrapper>
      </AdWrapper>
      <SectionContainer>
        <TopMenu>
          <FilterWrapper>
            {filterArray.map(item => (
              <Button
                disabled={isLoading}
                key={item}
                isActive={activeFilter === item}
                onClick={() => onClickFilter(item)}
              >
                {item}
              </Button>
            ))}
          </FilterWrapper>
        </TopMenu>
        <TournamentList
          tournamentList={tournamentListState}
          isLoading={isLoading}
          showFinishedTournaments={showFinishedTournaments}
          activeFilter={activeFilter}
          streamerData={tournamentStreaming}
        />
        <MobileSocialNav />
      </SectionContainer>
    </TournamentListPageWrapper>
  );
});

export default TournamentListPage;
