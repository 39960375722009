import moment from "moment";
import DOMPurify from "dompurify";

export const poolEntranceToDecimal = entrance => {
  try {
    const entranceLength = entrance.length;
    let result = null;
    if (entranceLength > 10) {
      result = entrance.slice(0, entranceLength - 10);
    }
    result = +result / 100000000;
    return result;
  } catch (e) {
    console.log("Error on poolEntranceToDecimal -> ", e);
    return 0;
  }
};

export const checkUserInPoolLeaderboard = (userId, poolLeaderboard) => {
  const user = poolLeaderboard.find(poolUser => poolUser.userId === userId);
  return !!user;
};

export const formatDateTime = dt => moment(dt).format("MMM DD, h:mm A");

export const cutUnderNDigits = (value, n) => Math.floor(value * 10 ** n) / 10 ** n;

export const capitalizeFirstLetter = (str = "") => {
  return str.slice(0, 1).toUpperCase() + str.slice(1)?.toLowerCase();
};

export const capitalizeOnlyFirstLetter = (str = "") => {
  return str.slice(0, 1).toUpperCase() + str.slice(1)?.toLowerCase();
};

export const truncateWithDots = (str = "", maxLength) => {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const calculateEarnings = session => {
  if (session && session.user && session.user.stats && session.user.stats.length > 0) {
    for (let i = 0; i < session.user.stats.length; i += 1) {
      if (session.user.stats[i].name === "MONEY_EARNED") {
        return session.user.stats[i].value;
      }
    }
  }
  return 0;
};

/**
 * The function pads a given string with a specified padding string and length.
 * @param [str] - The string that needs to be padded with the padStr.
 * @param [padStr=0] - The string to use for padding. By default, it is "0".
 * @param [len=2] - len is the length of the resulting string after padding. If the original string is
 * shorter than the specified length, it will be padded with the padStr character until it reaches the
 * desired length. If the original string is already longer than the specified length, it will not be
 * truncated. The default value for
 * @returns The `padString` function is returning a string with a length of `len` characters, where the
 * original `str` is padded with `padStr` at the beginning of the string. If `str` is not provided, an
 * empty string is used. If `padStr` is not provided, "0" is used as the default value. If `len` is not
 * provided,
 */
export const padString = (str = "", padStr = "0", len = 2) => {
  return str.toString()?.padStart(len, padStr);
};

/**
 * The function splits a notification message into its components, including a link, emoji, and
 * stripped message.
 * @returns A function that takes a message as input and returns an object with properties "link",
 * "linkText", "emoji", and "strippedMessage". If the input message includes the phrase "tournament has
 * just started", the function extracts a link and an emoji from the message and removes them from the
 * original message, returning the split message as an object. If the input message does not include
 * the phrase,
 */
export const splitDescriptionOfNotifications = message => {
  // if the message is null or undefined, return null
  if (!message) {
    return null;
    // check if the message contains but not starts with "tournament has just started"
  } else if (message.includes("tournament has just started")) {
    const linkRegex = /<a href="(.*?)">(.*?)<\/a>/;
    const linkMatch = message.match(linkRegex);
    const link = linkMatch ? linkMatch[1] : null; // the URL of the pool
    const linkText = linkMatch ? linkMatch[2] : null; // The text that it goes on the link

    // Find the rocket emoji using a regular expression
    const emojiRegex = /:[a-z_]+:/;
    const emojiMatch = message.match(emojiRegex);
    let emoji = "";
    // replace the emojiMatch with 🚀
    if (emojiMatch && emojiMatch[0]) {
      emoji = emojiMatch[0]?.replace(emojiMatch?.[0], "🚀");
    } else {
      emoji = null;
    }

    // Remove the link and emoji from the original message
    const strippedMessage = message.replace(linkRegex, "").replace(emojiRegex, "");

    // Return the split message as an object
    return {
      link: link || null,
      linkText: linkText || "Pool",
      emoji: emoji || "🚀",
      strippedMessage: strippedMessage.trim(),
    };
  } else {
    return {
      link: null,
      linkText: null,
      emoji: null,
      strippedMessage: message,
    };
  }
};

/**
 * The function calculates the percentage width of a player's score in a match.
 * @returns The function `calculatePercentageWidth` returns a string that represents the percentage of
 * the player's score out of the total match score. The string includes the percentage symbol at the
 * end.
 */
export const calculatePercentageWidth = (playerScore, matchScore) => {
  const percentage = (playerScore / matchScore) * 100;
  return `${percentage}%`;
};

/**
 * The `sanitizeHTML` function takes an HTML code as input and returns a sanitized version of it using
 * the DOMPurify library.
 * @returns the sanitized HTML code.
 */
export const sanitizeHTML = htmlCode => {
  const sanitizedHTML = DOMPurify.sanitize(htmlCode);
  return sanitizedHTML;
};

/**
 * The function `getResults` takes an array of team results and returns the number of wins and losses.
 * @param teamResults - An array of objects representing the results of a team's matches. Each object
 * has a property called "result" which can have a value of "W" (win) or "L" (loss).
 * @returns An object is being returned with two properties: "wins" and "losses". The value of "wins"
 * is the length of the array of team results where the result is "W", and the value of "losses" is the
 * length of the array of team results where the result is "L".
 */
export const getResults = (teamResults = []) => {
  const wins = teamResults.filter((team) => team.result === "W");
  const losses = teamResults.filter((team) => team.result === "L");

  // if wins or losses are undefined return 0 otherwise return the length
  if (!wins || !losses) return { wins: 0, losses: 0 };
  return {
    wins: wins.length,
    losses: losses.length,
  };
};

//function that takes a string and returns it in Uppercase
export const toUpperCase = str => {
  return str.toUpperCase();
};

/**
 * The function `sentRequestOnEnter` triggers a specified function when the Enter key is pressed.
 * @param e - The parameter `e` is typically an event object that represents the event that occurred,
 * such as a key press event in this case.
 * @param func - The `func` parameter in the `sentRequestOnEnter` function is a callback function that
 * will be executed when the Enter key (key code 13) is pressed.
 */
export const sentRequestOnEnter = (e, callback) => {
  if (e.keyCode === 13) {
    callback();
  }
};
