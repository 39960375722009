import styled, { keyframes } from "styled-components";
import Modal from "styled-react-modal";
import { Button } from "../../../common/Button";
import Avatar from "../../../common/Avatar";

export const PlayerRowWrapper = styled.div`
  background-color: ${props => (props.isMobile ? props.theme.hoverBackground : props.theme.darkBackground)};
  border-radius: 10px;
  margin-bottom: 11px;
  height: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.isMobile ? "0px 9px" : "0")};
  ${props =>
    props.currentUser
      ? ` background: 
      linear-gradient(${props.theme.darkBackground},${props.theme.darkBackground}) padding-box, /*this is your grey background*/
      linear-gradient(to right, #12C2EA, #FC74FF) border-box;
      color: #313149;
      border: 2px solid transparent;
      border-radius:15px;`
      : ``};

  :hover {
    cursor: pointer;
    background-color: #3f2faa;
  }
`;

export const DottedMenuCotnainer = styled.div`
  position: relative;
`;

export const PlayerInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  display: grid;
  grid-template-columns: ${props =>
    props.isMobile
      ? "1fr 5fr 1fr 1.4fr"
      : props.colCount
      ? `1fr 1.5fr repeat(${props.colCount}, 1fr) 0.2fr`
      : "1.5fr 2.5fr repeat(4, 1fr) 1.5fr 1fr"};
  /* @media (max-width: 450px) {
    grid-template-columns: ${props => (props.colCount ? `repeat(${props.colCount}, 1fr)` : "1.2fr 3.5fr 1fr 1fr")};
  } */
  justify-content: center;
  padding: ${props => (props.isMobile ? "0px" : "0px 10px")};
  padding-right:  10px;
`;

export const DottedMenuButton = styled.button`
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
`;

export const MenuContent = styled.div`
  width: 135px;
  background-color: #3f2faa;
  position: absolute;
  right: 0;
  border-radius: 6px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin: 15px 0px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #be7eff;
    }
  }
  button {
    margin: 15px 0px;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    border: none;
    &:hover {
      color: #be7eff;
    }
  }
  z-index: 999;
`;

export const ArrowWrapper = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  :hover {
    svg {
      path {
        fill: ${props => props.color};
      }
    }
  }
`;

export const WinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
`;

export const OrderText = styled.p`
  font-size: 16px;
  margin-left: 8px;
  margin-bottom: 0;
  color: ${props => (props.color ? props.color : "white")};
  margin-left: ${props => (props.win ? "8px" : "20px")};
  @media (max-width: 450px) {
    margin-left: ${props => (props.win ? "4px" : "16px")};
  }

`;

export const LeftWrapper = styled.div`
  text-align: left;
  width: 13%;
`;

export const NameWrapper = styled.div`
  @media (max-width: 450px) {
    justify-content: left;
  }
`;

export const DotsWrapper = styled.div`
  text-align: right;
  margin-rught: 27px;
`;

export const NameText = styled.p`
  font-size: 16px;
  color: white;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media (max-width: 450px) {
    font-size: 14px;
    text-align: left;
    width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ScoreText = styled.p`
  font-size: 14px;
  color: white;
  margin-bottom: 0;
  text-align: ${props => (props.isMobile ? "center" : "unset")};
  margin-left: 10px;
`;

export const DetailButton = styled(Button)`
  position: absolute;
  background: #342e5e;
  top: 25px;
  right: -10px;
  text-align: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const MoreBtnWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
`;

export const MoreBtn = styled.span`
  cursor: pointer;
  padding: 5px;
  padding-right: ${props => (props.isMobile ? "0px" : "20px")};
  @media (max-width: 450px) {
    justify-content: right;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const AvatarImage = styled(Avatar)`
  height: 33px;
  min-width: 33px;
  display: block;
  background: ${props => (props.thumbnail ? `url(${props.thumbnail})` : "")};
  background-size: contain;
  border-radius: 50px;
`;

export const ReportModal = Modal.styled`
    width: ${props => (props.isMobile ? "354px" : "378px")};
    height:  ${props => (props.isMobile ? "fit-content" : "306px")};
    display: flex;
    flex-direction: column;
    background-color: #272054;
    // border: ${props => (props.error ? "1px solid #D06565" : "none")};
    border-radius: 4px;
    opacity: 1;
    transition : all 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    padding: 30px 30px 25px 25px; 
    transform: translateY(-50%);
    @media (max-width: 450px) {
      top: 20%;
      transform: translateY(-20%);
    }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
`;

export const CheckBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0px 11px;
  border: 2px solid;
  border-color: ${props => (props.checked ? "#7485ff" : "#3A2F81")};
  border-radius: 4px;
  box-sizing: border-box;
  background: ${props => (props.checked ? "#3A2F81" : "#272054")};
  :hover {
    background: #3a2f81;
  }
  cursor: pointer;
`;

export const CheckBoxText = styled.p`
  position: relative;
  bottom: 5px;
  width: 260px;
  height: 32px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #877add;
  color: rgba(209, 204, 239, 1);
  cursor: pointer;
  width: min-content;
`;

export const ReportModalHeader = styled.p`
  margin-bottom: 28px;
  line-height: 30px;
  color: rgba(209, 204, 239, 1);
  font-size: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 19px;
`;

export const PrimaryButton = styled.button`
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  color: #fff;
  border-radius: 30px;
  height: 30px;
  width: 103px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
`;

export const SecondaryButton = styled.button`
  background: transparent;
  color: rgba(102, 91, 172, 1);
  font-size: 14px;
  width: 81px;
  border: none;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

export const LoadingIndicatorAnimation = keyframes`
  from { 
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIndicatorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  animation: ${LoadingIndicatorAnimation} 1s linear infinite;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 17px;
  cursor: pointer;
`;
